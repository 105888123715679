import { IAction } from "../../redux/types/acount-interface";
import {
  actionType,
  handleFilterType,
  helperHandleMuteConversation,
  helperHandleReadConversation,
  helperHandleSeeConversation,
  helperRemove,
  helperUpdateMessage,
  Imessage,
  IPropsDiscussion,
  ItemDiscussion,
} from "./type";

export const reducer = (
  state: IPropsDiscussion,
  action: IAction
): IPropsDiscussion => {
  switch (action.type) {
    case actionType.GET_INFO_CONVERSATION: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload?.sendTo,
          discussionType: action.payload.type,
          idConversation: action.payload?.result?.id_conversation,
          isNull: false,
        },
        [handleFilterType(action.payload?.type)]: {
          infoConversation: action.payload?.result,
        },
      };
    }
    case actionType.RESTORE_CURRENT_INFO_CONVERSATION: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: 0,
          discussionType: "",
          idConversation: 0,
          isNull: false,
        },
        [handleFilterType(action.payload?.type)]: {
          infoConversation: null,
        },
      };
    }

    case actionType.GET_INFO_CONVERSATION_ON_CLICK: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload?.sendTo,
          discussionType: action.payload.type,
          idConversation: action.payload?.result?.id_conversation,
          isNull: false,
        },
        [handleFilterType(action.payload?.type)]: {
          infoConversation: {
            id_conversation: action?.payload?.result?.id_conversation,
            informations_send_to: {
              ...action.payload?.result,
            },
          },
        },
      };
    }

    case actionType.GET_UNREAD_CATEGORY: {
      return {
        ...state,
        unreadeInCategory: action.payload,
      };
    }

    case actionType.GET_ALL_CONVERSATION: {
      return {
        ...state,
        allDiscussion: action.payload,
      };
    }

    case actionType.HANDLE_CONVERSATION_IN_MERCURE: {
      return {
        ...state,
        allDiscussion: [...action.payload],
      };
    }

    case actionType.HANDLE_CONVERSATION_ID: {
      return {
        ...state,
        currentInfoToSendMessage: {
          ...state.currentInfoToSendMessage,
          idConversation: action.payload,
          isNull: false,
        },
      };
    }

    case actionType.GET_ALL_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages:
            state.paginateMessage.messages.length == 1
              ? action.payload
              : [...action.payload, ...state.paginateMessage.messages],
          page: 1,
        },
      };
    }

    case actionType.SET_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages: helperUpdateMessage(
            state.paginateMessage.messages,
            action.payload
          ),
          page: 0,
        },
      };
    }

    case actionType.HANDLE_CURRENT_INFO_MESSAGE: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload.sendTo ? action.payload.sendTo : "",
          discussionType: action.payload.discussionType
            ? action.payload.discussionType
            : "",
          idConversation: action.payload.idConversation
            ? action.payload.idConversation
            : "",
          isNull: false,
        },

        paginateMessage: {
          messages: [],
          page: 0,
        },
        allFilePerDiscussion: [],
      };
    }

    case actionType.LOAD_MORE_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages: [
            ...action.payload.allMessage,
            ...state.paginateMessage.messages,
          ],
          page: action.payload.currentPage,
        },
      };
    }

    case actionType.RESTORE_CURRENT_MESSAGE_INFO: {
      return {
        ...state,
        paginateMessage: {
          messages: [],
          page: 0,
        },
        allDiscussion: [],
        allFilePerDiscussion: [],
        currentInfoToSendMessage: {
          sendTo: 0,
          discussionType: "",
          idConversation: 0,
          isNull: false,
        },
      };
    }

    case actionType.SET_OEM_PARTICIPANT: {
      return {
        ...state,
        oem_to_isv: {
          ...state.oem_to_isv,
          infoConversation: {
            ...state.oem_to_isv?.infoConversation,
            informations_send_to: {
              ...state?.oem_to_isv?.infoConversation?.informations_send_to,
              program_representatives: action.payload,
            },
          },
        },
        isv_to_oem: {
          ...state.isv_to_oem,
          infoConversation: {
            ...state.isv_to_oem?.infoConversation,
            informations_send_to: {
              ...state.isv_to_oem.infoConversation?.informations_send_to,
              representative: action.payload,
            },
          },
        },
      };
    }

    case actionType.REMOVE_CONVERSATION: {
      return {
        ...state,
        allDiscussion: helperRemove(state.allDiscussion, action.payload),
        paginateMessage: {
          messages: [],
          page: 0,
        },
        allFilePerDiscussion: [],
      };
    }

    case actionType.ADD_ARCHIVE: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_ARCHIVE: state.unreadeInCategory?.NUMBER_ARCHIVE + 1,
        },
      };
    }

    case actionType.ADD_ONE_REQUEST: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_REQUEST: state.unreadeInCategory?.NUMBER_REQUEST + 1,
        },
      };
    }

    case actionType.REMOVE_CURRENT_CONV: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: -1,
          discussionType: "",
          idConversation: -1,
          isNull: true,
        },
      };
    }

    case actionType.HANDLE_SEE_CONVERSATION: {
      return {
        ...state,
        allDiscussion: helperHandleSeeConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.HANDLE_MUTED: {
      return {
        ...state,
        allDiscussion: helperHandleMuteConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.HANDLE_READ: {
      return {
        ...state,
        allDiscussion: helperHandleReadConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.GET_FILE_PER_CONVERSATION: {
      return {
        ...state,
        allFilePerDiscussion: action.payload,
      };
    }

    case actionType.REMOVE_ONE_ARCHIVE: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_ARCHIVE:
            state.unreadeInCategory?.NUMBER_ARCHIVE > 0
              ? state.unreadeInCategory?.NUMBER_ARCHIVE - 1
              : state.unreadeInCategory?.NUMBER_ARCHIVE,
        },
      };
    }

    case actionType.REMOVE_ONE_REQUEST: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_REQUEST:
            state.unreadeInCategory?.NUMBER_REQUEST > 0
              ? state.unreadeInCategory?.NUMBER_REQUEST - 1
              : state.unreadeInCategory?.NUMBER_REQUEST,
        },
        paginateMessage: {
          messages: [],
          page: 1,
        },
      };
    }

    case actionType.REMOVE_ALL_CONVERSATION: {
      return {
        ...state,
        allDiscussion: [],
      };
    }

    case actionType.HANDLE_NBR_REQUEST: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_REQUEST: state?.unreadeInCategory.NUMBER_REQUEST + 1,
        },
      };
    }

    case actionType.RESTORE_STATE_FILTER_CONVERSATION: {
      return {
        ...state,
        currentInfoToSendMessage: {
          ...state.currentInfoToSendMessage,
          sendTo: 0,
          discussionType: "",
          // idConversation: 0,
        },
        allDiscussion: [],
        paginateMessage: {
          messages: [],
          page: 0,
        },
      };
    }

    case actionType.RESTORE_MESSAGE_CURRENT_DISCUSSION: {
      return {
        ...state,
        paginateMessage: {
          messages: [],
          page: 0,
        },
      };
    }

    case actionType.HANDLE_ADD_MEMBER_OEM: {
      return {
        ...state,
        oem_to_isv: {
          ...state.oem_to_isv,
          infoConversation: {
            ...state.oem_to_isv.infoConversation,
            informations_send_to: {
              ...state.oem_to_isv.infoConversation.informations_send_to,
              program_representatives: action.payload,
            },
          },
        },
      };
    }

    case actionType.SET_MESSAGE_FILTER: {
      return {
        ...state,
        paginateMessage: {
          messages: [...action.payload],
          page: 1,
        },
      };
    }
    case actionType.PAGINATE_SEARCH_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages:
            action.payload.typeScroll === "top"
              ? [...action.payload.data, ...state.paginateMessage.messages]
              : [...state.paginateMessage.messages, ...action.payload.data],
          page: 1,
        },
      };
    }

    case actionType.SET_OPTIMISTIC_MESSAGE: {
      const optimisticMessage = action.payload;
      console.log("optimistic message", optimisticMessage);

      return {
        ...state,
        paginateMessage: {
          ...state.paginateMessage,
          messages: [...(state.paginateMessage?.messages || []), optimisticMessage]
        },
        allDiscussion: (state.allDiscussion || []).map(discussion => 
          optimisticMessage?.conversation?.id === -1 || discussion?.id_conversation === optimisticMessage?.conversation?.id
            ? {
                ...discussion,
                last_message: optimisticMessage
              }
            : discussion
        )
      };
    }

    case actionType.UPDATE_MESSAGE_SUCCESS: {
      const updatedMessage = action.payload;
      console.log("action", updatedMessage);
      console.log("action2", state.paginateMessage?.messages);
      console.log("action3", state.allDiscussion);
  
      const conversationExists = state.allDiscussion?.some(
        disc => disc.id_conversation === updatedMessage?.conversation_id
      );

      console.log("exists", conversationExists);

      const formattedMessage: Imessage = {
        id: updatedMessage.message_id,
        temp_id: updatedMessage.temp_id,
        isInvitation: updatedMessage.isInvitation || false,
        sender: {
            id: Number(updatedMessage.message?.sender?.id) || 0,
            last_name: updatedMessage.sender?.last_name || "",
            first_name: updatedMessage.sender?.first_name || "",
            file_name: updatedMessage.sender?.file_name || "",
            company_name: updatedMessage.message?.sender?.company_name || "",
            job_title: updatedMessage.sender?.job_title || ""
        },
        conversation: {
            id: updatedMessage.conversation_id,
            type: state.currentInfoToSendMessage?.discussionType || "",
            created_at: new Date().toISOString()
        },
        message: updatedMessage.message?.message || "",
        created_at: updatedMessage.message?.createdAt || new Date().toISOString(),
        attachements: updatedMessage.message?.attachements || [],
        me: updatedMessage.message?.me
      };
      
      return {
          ...state,
          // paginateMessage: {
          //     ...state.paginateMessage,
          //     messages: (state.paginateMessage?.messages || []).map(msg =>
          //         msg?.temp_id === updatedMessage?.temp_id 
          //             ? { 
          //                 ...msg, 
          //                 // message: updatedMessage?.message,
          //                 conversation: {
          //                     id: updatedMessage?.conversation_id
          //                 },
          //                 ...updatedMessage 
          //             } 
          //             : msg
          //     )
          // },
          paginateMessage: {
            ...state.paginateMessage,
            messages: (state.paginateMessage?.messages || []).map(msg =>
                msg?.temp_id === updatedMessage?.temp_id 
                    ? formattedMessage 
                    : msg
            )
          },
          // allDiscussion: conversationExists 
          //   ? (state.allDiscussion || []).map(discussion =>
          //       discussion?.id_conversation === updatedMessage?.conversation_id
          //           ? {
          //               ...discussion,
          //               last_message: [formattedMessage]
          //           }
          //           : discussion
          //   )
          //   : [
          //       {
          //           id_conversation: updatedMessage.conversation_id,
          //           is_seen: true,
          //           is_unread: false,
          //           is_archived: false,
          //           is_muted: false,
          //           is_request: false,
          //           roles_sender: updatedMessage?.message?.sender?.roles || [],
          //           type_conversation: state.currentInfoToSendMessage?.discussionType || "",
          //           last_message: [formattedMessage],
          //           match_count: 0,
          //           company_to_send: {
          //               id: 0, // À ajuster selon votre logique
          //               company_name: "",
          //               photo_profil: ""
          //           },
          //           user_to_send: {
          //               id: Number(updatedMessage.message?.sender?.id) || 0,
          //               first_name: updatedMessage.sender?.first_name || "",
          //               last_name: updatedMessage.sender?.last_name || "",
          //               file_name: updatedMessage.sender?.file_name || ""
          //           },
          //           destination_type: state.currentInfoToSendMessage?.discussionType || ""
          //       } as ItemDiscussion,
          //       ...(state.allDiscussion || [])

          //   ] as ItemDiscussion[]
          // allDiscussion: (state.allDiscussion || []).map(discussion =>
          //   discussion?.id_conversation === updatedMessage?.conversation_id
          //       ? {
          //           ...discussion,
          //           last_message: [formattedMessage]
          //       }
          //       : discussion
        // )
          allDiscussion: (state.allDiscussion || []).map(discussion =>
                  discussion?.id_conversation === updatedMessage?.conversation_id
                      ? {
                          ...discussion,
                          last_message: [formattedMessage]
                      }
                      : discussion
              )
            
      };
      
    }

    case actionType.UPDATE_MESSAGE_ERROR: {
      // Supprimer le message optimiste en cas d'erreur
      return {
        ...state,
        paginateMessage: {
          ...state.paginateMessage,
          messages: (state.paginateMessage?.messages || []).filter(
            msg => msg?.temp_id !== action.payload.tempId
          )
        }
      };
    }

    default:
      return state;
  }
};
