import STATIC from "./constant/index";
import store from "../redux/store";
import {
  logout,
  updateTokenAndRefreshTokenAction,
} from "../redux/actions/acounts-action";
import kc from "../keycloak";

const axios = require("axios");

export interface dataWithToken {
  url: string;
  data: any;
  token?: any;
}
/**
 * @param arg string type request
 * @param token optionelle token parametre
 * @returns {object} object header with token
 */
const headers = (arg = "application/json", token = "") => ({
  "Content-Type": arg,
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
});

/**
 * @param arg string type request
 * @returns {object} object header without token
 */
const headerWithoutToken = (arg = "application/json") => ({
  "Content-Type": arg,
  Accept: "application/json",
});

/***
 *@returns instance of axios
 */
const axiosProvider = axios.create({
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  timeout: 1200000,
  baseURL: STATIC.baseUrl,
});

// Response interceptor for API calls
const interceptorResponse = axiosProvider.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    let data = { refresh_token: store.getState()?.userReducer?.refreshToken };
    const originalRequest = error.config;
    // if (
    //   error.response?.status === 401 &&
    //   originalRequest?.url === "/api/token/refresh"
    // ) {
    //   store?.dispatch(
    //     logout(() => {
    //       kc.logout({redirectUri:"/"})
    //     })
    //   );
    //   return;
    // }
    return Promise.reject(error);
    // if (
    //   error.response?.status === 401 &&
    //   !originalRequest._retry &&
    //   originalRequest?.url !== "/api/login_check"
    // ) {
    //   originalRequest._retry = true;
    //   let resposeWs = await postWithoutToken("/api/token/refresh", data);

    //   if (resposeWs?.status === 500) {
    //     kc.logout({redirectUri:"/"})
    //     return;
    //   }
    //   if (resposeWs?.data) {
    //     store?.dispatch(
    //       updateTokenAndRefreshTokenAction(resposeWs?.data, (v: any) => {})
    //     );
    //   }
    //   axiosProvider.defaults.headers.common["Authorization"] =
    //     "Bearer " + resposeWs?.data?.token;
    //   return axiosProvider(originalRequest);
    // } else {
    //   return Promise.reject(error);
    // }
  }
);

axios.interceptors.response.eject(interceptorResponse);

/***
 *@returns instance of axios
 */
const axiosMicroserviceProvider = axios.create({
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  timeout: 1200000,
  baseURL: STATIC.microserviceUrl,
});

/***
 *@returns instance of axios
 */
const axiosStaticDataProvider = axios.create({
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  timeout: 1200000,
  baseURL: STATIC.staticDataUrl,
});

/**
 * @param data data to send
 * @param argument of data to check if form data or string
 */
export const datas = (data: any, arg = "application/json") => {
  if (arg === "application/json") {
    return JSON.stringify(data);
  } else if (arg === "multipart/form-data") {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  } else {
    return data;
  }
};

/**
 * @param url string url to call api post
 * @param data object to send
 * @param token token to send
 * @returns result of post response
 */
export const postData = async (url: string, data: any, token: any) => {
  try {
    const result = await axiosProvider({
      method: "POST",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(data),
    });
    return result;
  } catch (exception: any) {
    // if (window.location.href.includes('reply-post')) window.location.href = '/';
  }
};

export const asyncPostData = (url: string, data: any, token: any) => {
  return axiosProvider({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const patchData = async (url: string, data: any, token: any) => {
  const result = await axiosProvider({
    method: "PATCH",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: JSON.stringify(data),
  });
  return result;
};
/**
 * @param url string url to call api post
 * @param token token to send
 * @returns result of get response
 */
export const getData = async (url: string, token: any) => {
  try {
    const result = await axiosProvider({
      method: "get",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    return result;
  } catch (e: any) {
    return e.response;
  }
};
/**
 * @param params object to send
 * @returns result of get response
 */
export const putData = async (params: dataWithToken) => {
  try {
    const result = axiosProvider({
      method: "put",
      url: params.url,
      data: params.data,
      headers: {
        Authorization: `Bearer ${params.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return result;
  } catch (e: any) {
    return null;
  }
};

export const deleteData = async (params: dataWithToken) => {
  try {
    return axiosProvider({
      method: "delete",
      url: params.url,
      headers: {
        Authorization: `Bearer ${params?.token}`,
        "Content-Type": "text/plain",
        Accept: "application/json",
      },
    });
  } catch (e: any) {
    return e;
  }
};

export const deleteWithToken = async (params: dataWithToken) => {
  try {
    const result = axiosProvider({
      method: "delete",
      url: params?.url,
      data: params?.data,
      headers: {
        Authorization: `Bearer ${params?.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return result;
  } catch (e: any) {
    return null;
  }
};

export const putPhoto = async (params: dataWithToken, arg: any) => {
  try {
    const result = await axiosProvider({
      method: "put",
      url: params.url,
      data: datas(params?.data, arg),
      headers: headers(arg, params.token),
    });
    return result;
  } catch (e: any) {
    return null;
  }
};

// Dev #47679 OPEN V1 Agrandir la taille max de la video dans company page
export const postPhoto = async (
  params: dataWithToken,
  token: string = "",
  callBackProgression: any = null
) => {
  try {
    const result = await axiosProvider({
      method: "POST",
      url: params.url,
      data: datas(params.data, "multipart/form-data"),
      headers: headers("multipart/form-data", token),
      onUploadProgress: (progressEvent: any) => {
        console.log("onUploadProgress", progressEvent);
        if (callBackProgression !== null) callBackProgression(progressEvent);
      },
    });
    return result;
  } catch (error) {
    return error;
  }
};

export const asyncPostPhoto = (
  params: FormData,
  url: string,
  token: string = "",
  callBackProgression: any = null
) => {
  return axiosProvider({
    method: "POST",
    url: url,
    data: params,
    headers: headers("multipart/form-data", token),
    onUploadProgress: (progressEvent: any) => {
      if (callBackProgression !== null) callBackProgression(progressEvent);
    },
  });
};

export const postWithoutToken = async (url: string, data: any, arg?: any) => {
  try {
    const result = await axiosProvider({
      method: "post",
      url: url,
      data: data,
      headers: headerWithoutToken(),
    });
    return result;
  } catch (error: any) {
    return error?.response;
  }
};

export const postWithoutTokenCombineUrl = async (
  url: string,
  param: string
) => {
  return axiosProvider({
    method: "post",
    url: `${url}/${param}`,
    headers: headerWithoutToken(),
  });
};

export const getDataWithoutToken = async (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosProvider({
    method: "get",
    url,
    headers: headers("application/json", token),
    params: data,
  });
};

export const getMicroserviceDataWithToken = (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosMicroserviceProvider({
    method: "get",
    url,
    headers: headers("application/json", token),
    params: data,
  });
};

export const getStaticDataWithToken = (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosStaticDataProvider({
    method: "get",
    url,
    headers: headers("application/json", token),
    params: data,
  });
};

export const deleteMicroserviceDataWithToken = (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosMicroserviceProvider({
    method: "delete",
    url,
    headers: headers("application/json", token),
    params: data,
  });
};

export const postMicroserviceDataWithToken = (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosMicroserviceProvider({
    method: "post",
    url,
    headers: headers("application/json", token),
    data: data,
  });
};

export const getDataMicroService = (
  url: string,
  data?: any,
  token?: string
) => {
  return axiosMicroserviceProvider({
    method: "get",
    url,
    headers: headers("application/json", token),
    params: data,
  });
};

export const getDataParam = async (url: string, data?: any, token?: string) => {
  return axiosProvider({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: data,
  });
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else if (userAgent.match(/trident/i)) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Navigateur inconnu";
  }

  return browserName;

}

//https://www.smashingmagazine.com/2020/05/typescript-modern-react-projects-webpack-babel/
