/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteContributorAccount from "../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-contributor-account";
import { ReactComponent as Loader } from "../../../../../resources/images/Double-Ring.svg";
import { useKeycloak } from "@react-keycloak/web";

export default function CompleteContributorAccount() {
  const { params, showLoader, resendEmail } = UseCompleteContributorAccount();
  const {keycloak} = useKeycloak()
  if (params === undefined) {
    return keycloak.login()
  }

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <h1>Thank you for your form submission.</h1>
        </div>
        <div className="contentBorder border-top border-bottom stepfinal">
          <p className="mail">{params?.email}</p>
          <p>
            We are currently processing your submission. This usually takes less
            than 48 hours.
            <br />
            We'll be in touch soon!
            <br />
            If you don't receive an email to activate your account within 48
            hours, you can resend your validation request. &nbsp;
            <a
              className="resend-mail"
              onClick={(e) => {
                e.preventDefault();
                resendEmail();
              }}
            >
              re-send your validation request
              {showLoader && <Loader />}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
