import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
} from "react-router-dom";

import { rootState } from "../../../../redux/reducers";
import {
  sendInvitationAffilliated,
  removeInvitationAction,
  updateRoleAffiliatedAction,
  removeUserRoleAffilliatedAction,
  validRequestToAffiliatedAction,
} from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import { toast } from "react-toastify";
import { checkIfEmailExist } from "../../../../service/applicatif/acount";
import { trim } from "lodash";

export default function UseAffiliatedCtr() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const [isvMemberSelected, setIsvMemberSelected] = useState<any>();
  const [showModalRemove, setShowModalRemove] = useState<boolean>(false);

  const affiliatedType = [
    {
      id: 1,
      label: "Admin",
      value: "ADMIN",
    },
    {
      id: 2,
      label: "Member",
      value: "MEMBER",
    },
  ];
  const [emailInvite, setEmailInvite] = useState<string>("");
  const [typeAffiliated, setTypeAffiliated] = useState<string>("ADMIN");
  const [idRemove, setIdRemove] = useState<string>("");

  const [showRequestAffiliatedInsider, setShowRequestAffiliatedInsider] =
    useState(location?.state?.showModal ? true : false);
  const [loaderAffiliated, setLoaderAffiliated] = useState<boolean>(false);
  const [loaderRemoveMember, setLoaderRemoveMember] = useState<boolean>(false);
  const [loaderUpdateRole, setLoaderUpdateRole] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const [hideModalBillingCenter, setHideModalBillingCenter] =
    useState<boolean>(false);

  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  let userObject = useSelector((state: rootState) => state.userReducer);

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [txtMessage, setTxtMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<boolean>(false);
  const [roleAffiliated, setRoleAffiliated] = useState<{
    role: string;
    idUser: string;
  }>();

  const [roleOfAccepteRequest, setroleOfAccepteRequest] =
    useState<string>("ADMIN");

  const cbSendAffilliated = (data: any) => {
    setShowMessage(true);
    setLoaderAffiliated(false);
    if (data?.status == 201) {
      setTypeMessage(true);
      setTxtMessage(emailInvite + " is invited successfully");
    } else if (data?.status === 400) {
      setTypeMessage(false);
      setTxtMessage(data?.data?.detail);
    } else {
      setTypeMessage(false);
      setTxtMessage("An error occurred , verify your email address");
    }
    setEmailInvite("");
  };

  const sendInvitation = async () => {
    setLoaderAffiliated(true);
    if (showMessage) {
      setShowMessage(false);
    }

    const params = {
      email: trim(emailInvite),
      role: typeAffiliated,
      companyPage: "/api/company_pages/" + companyPageObject?.id,
    };
    dispatch(sendInvitationAffilliated(params, cbSendAffilliated));
      
  };



  const cbRemoveInvitation = (data: any) => {
    setIdRemove("");
  };

  const removeInvitation = (id: any) => {
    if (showMessage) {
      setShowMessage(false);
    }
    setIdRemove(id?.toString());
    dispatch(removeInvitationAction(id, cbRemoveInvitation));
  };

  const cbUpdateRole = (data: any) => {
    setLoaderUpdateRole(false);
    setRoleAffiliated({ idUser: "", role: "" });
    if (data?.status === 201) {
      toast("Role update successfully");
    }
  };

  useEffect(() => {
    if (roleAffiliated?.idUser && roleAffiliated?.role) {
      setLoaderUpdateRole(true);
      let params = {
        role: roleAffiliated?.role,
        user: "/api/users/" + roleAffiliated?.idUser,
        idUser: roleAffiliated?.idUser,
        companyPage: "/api/company_pages/" + companyPageObject?.id,
      };
      dispatch(updateRoleAffiliatedAction(params, cbUpdateRole));
    }
    return () => {};
  }, [roleAffiliated]);

  useEffect(() => {
    setEmailInvite("");
    setTypeMessage(false);
    return () => {};
  }, [showRequestAffiliatedInsider]);

  const cbRemoveRole = (data: any) => {
    toast("The user has been removed successfully");
    setIsvMemberSelected(null);
    setLoaderRemoveMember(false);
    setShowModalRemove(false);
  };

  const removeRole = (id: string) => {
    setLoaderRemoveMember(true);
    dispatch(removeUserRoleAffilliatedAction(id, cbRemoveRole));
  };

  const closeAffiliatedValue = () => {
    if (emailInvite) {
      setEmailInvite("");
    }
    if (showMessage) {
      setShowMessage(false);
    }
    if (txtMessage) {
      setTxtMessage("");
    }
    setShowRequestAffiliatedInsider(false);
  };

  const cbValidRequest = async (params: any,type:string) => {
    setLoadingRequest(false)
    console.log('cbValidRequestcbValidRequest', params ,type)
    if(type==="MEMBER_INVITATION_DENY"){
      toast("The request was rejected.");
    }
    if(type==="MEMBER_INVITATION_APPROVE"){
      toast("The request was approved.");
    }
  };

  const validRequest = (p: any) => {
    setLoadingRequest(true)
    let params = {
      invitationId: p?.invitationId,
      role: roleOfAccepteRequest,
      decision: p?.event,
    };
    dispatch(validRequestToAffiliatedAction(params, (datas:any) => cbValidRequest(datas,p?.event)));
  };

  const goToSettings = () => {
    history?.push({
      pathname: "/settings",
      state: {
        tabToShow: "billing",
      },
    });
  };

  return {
    affiliatedType,
    emailInvite,
    showRequestAffiliatedInsider,
    loaderAffiliated,
    typeAffiliated,
    typeMessage,
    showMessage,
    txtMessage,
    idRemove,
    loaderUpdateRole,
    roleAffiliated,
    setShowRequestAffiliatedInsider,
    setEmailInvite,
    setTypeAffiliated,
    sendInvitation,
    removeInvitation,
    setRoleAffiliated,
    removeRole,
    closeAffiliatedValue,
    roleOfAccepteRequest,
    setroleOfAccepteRequest,
    loaderRemoveMember,
    validRequest,
    goToSettings,
    currentId: userObject?.id,
    currentUserCompanyRole: userObject?.isvInCompanyPage?.role,
    affiliatedList: companyPageObject?.isvInCompanyPage,
    invitation: companyPageObject?.membersInvitations,
    isvMemberSelected,
    showModalRemove,
    setIsvMemberSelected,
    setShowModalRemove,
    hideModalBillingCenter,
    setHideModalBillingCenter,
    loadingRequest
  };
}