import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useEventListener from "../../hooks/event-handler";
import { IsvProgramType } from "../../interface/isv-type";
import { rootState } from "../../redux/reducers";
import { IAction } from "../../redux/types/acount-interface";
import UseUnlockInvestorIsv from "../../screen/widget/payement-investors/unlock-isv/use-unlock-investor";
import UseOemUnlockIsv from "../../screen/widget/payement-oem/unlock-isv/use-unlock-isv";
import { patchDataService } from "../../service/applicatif/back-end-service";
import { INVESTOR, ISV, OEM_PROGRAM, url } from "../../service/constant";
import { conversationType } from "../../utils";
import UseEventFilters from "./use-handle-event-filter-ctr";

import { toast } from "react-toastify";
import {
  handleBillingUnlockIsv,
  handleUpdateAllowUnlockStatus,
  updateOemProgramBillingDetails,
} from "../../redux/actions/oem-program-action";
import { asyncPostData } from "../../service/api";
import { checkUnlockPayment } from "../../service/applicatif/oem";
import { clearOtherIsvCahe } from "../../redux/actions/company-page-isv-action/other_isv_action";
const GET_ISV_MATCHING = "GET_ISV_MATCHING";
const ADD_FAVORITE = "ADD_FAVORITE";
const UNLOCK = "UNLOCK";

type IPropsInitialState = {
  isv: Array<IsvProgramType>;
  currentPage: number;
  totalDataPaginate: number;
  unlocked_left: number;
};
const initialState: IPropsInitialState = {
  isv: [],
  totalDataPaginate: 0,
  currentPage: 0,
  unlocked_left: 0,
};

const helperAddFavorie = (state: Array<any>, id: number) => {
  return state?.map((el: any) => {
    if (el?.isv?.id?.toString() === id?.toString()) {
      return {
        ...el,
        is_favorite: !el?.is_favorite,
      };
    } else return el;
  });
};

const helperUnlocked = (state: Array<any>, data: any) => {
  console.log(state);
  console.log(data);
  return state?.map((el: any) => {
    if (el?.isv?.id?.toString() === data?.isv?.id?.toString()) {
      return data;
    } else return el;
  });
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_ISV_MATCHING:
      return {
        ...state,
        isv: action.payload?.isvListe,
        currentPage: action?.payload?.currentPage,
        totalDataPaginate: action.payload?.totalDataPaginate,
        unlocked_left: action.payload.unlocked_left,
      };

    case ADD_FAVORITE:
      return {
        ...state,
        isv: helperAddFavorie(state?.isv, action.payload),
      };

    case UNLOCK: {
      return {
        ...state,
        isv: helperUnlocked(state?.isv, action.payload),
        unlocked_left: state.unlocked_left - 1,
      };
    }

    default:
      return state;
  }
};

const filtered = [
  {
    value: "relevance",
    label: "Relevance",
  },
  {
    value: "last_update",
    label: "Last Update",
  },
  {
    value: "last_unlocked",
    label: "Last Unlocked",
  },
];

export default function UseMatchingIsv() {
  const {
    sliderValue,
    selectedHideType,
    selectedCountry,
    selectedSegment,
    selectedTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleHideType,
    handleCountry,
    handleSegment,
    handleTargeted,
    handleCompanySize,
    handleAnnual,
    resetFilterEvent,
    setSliderValue,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
  } = UseEventFilters();
  const userState = useSelector((state: rootState) => state.userReducer);

  const isOemUser = userState?.roles?.[0] === OEM_PROGRAM;
  const isInvestor = userState?.roles?.[0] === INVESTOR;
  const isIsv = userState?.roles?.[0] === ISV;

  var ProviderinvestorUnlockIsv: any;
  var ProviderOemUnlockIsv: any;

  if (isInvestor) {
    ProviderinvestorUnlockIsv = UseUnlockInvestorIsv();
  }

  if (isOemUser) {
    ProviderOemUnlockIsv = UseOemUnlockIsv();
  }
  const [orderBy, setOrderBy] = useState("DESC");

  const [search_name, setSearch_name] = useState("");

  const [page, setPage] = useState(1);

  const [sortBy, setSortBy] = useState<string>("relevance");
  const [loaderMatching, setloaderMatching] = useState<boolean>(false);

  const [allIsvMatching, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);

  const dispatchRedux = useDispatch();

  const [limit, setlimit] = useState<number>(24);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const [isAccountNotActive, setIsAccountNotActive] = useState<boolean>(false);

  const isMounted = useRef(true);

  const history = useHistory();

  let oemParams = {
    oem: selectedOemProgram?.program?.id,
  };

  let investoPrams = {
    funding_round: selectedFunding?.join(),
    fundraising_goal: selectedFundraising?.join(),
  };

  let defaultCombine = {
    hide_unlocked: selectedHideType?.find((el) => el?.toString() === "1")
      ? "yes"
      : "no",
    hide_blacklisted: selectedHideType?.find((el) => el?.toString() === "2")
      ? "yes"
      : "no",
    hide_joining_soon: selectedHideType?.find((el) => el?.toString() === "3")
      ? "yes"
      : "no",
    minimumMatch: sliderValue[0],
    maximumMatch: sliderValue[1],
    country: selectedCountry?.join(),
    segment: selectedSegment?.join(),
    targeted_industry: selectedTargeted?.join(),
    company_size: selectedCompanySize?.join(),
    annual_turnover: selectedAnnual?.join(),
    sortBy,
    orderBy,
    searchCompanyName: search_name,
    page,
  };

  let defaultParams = isOemUser
    ? { ...defaultCombine, ...oemParams }
    : { ...defaultCombine, ...investoPrams };

  const handleFilter = (params: any) => {
    setSortBy(params?.value);
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      sortBy: params?.value,
      page: 1,
    };
    getAllIsvMatching(paramsChange, dispatch);
  };

  const getAllIsvMatching = (params: any, dispatch: any, recursion = false) => {
    if (!recursion) {
      setloaderMatching(true);
      isAccountNotActive && setIsAccountNotActive(false);
    }

    let urlMatching = isOemUser
      ? url?.findIsv?.get_isv_matvhing
      : url?.matching_investor_isv?.matching_investor_isv;

    asyncPostData(urlMatching, params, userState?.token)
      .then(
        (response: {
          status: number;
          data: {
            progress: any;
            results: any;
            total_to_paginate: any;
            unlocked_left: any;
            is_validate: any;
          };
        }) => {
          if (isMounted.current) {
            if (response?.status === 200) {
              const inProgress = response?.data?.results === "IN_PROGRESS";
              const result = {
                isvListe: inProgress
                  ? "Matching calculation progress: " +
                    response?.data?.progress +
                    "%"
                  : response?.data?.results,
                totalDataPaginate: response?.data?.total_to_paginate,
                currentPage: params?.page,
                unlocked_left: response?.data?.unlocked_left,
              };
              if (response?.data?.is_validate) {
                dispatch({ type: GET_ISV_MATCHING, payload: result });
                dispatchRedux(handleBillingUnlockIsv(result.unlocked_left));
              } else {
                setIsAccountNotActive(true);
              }

              if (response?.data?.results === "IN_PROGRESS") {
                setTimeout(() => {
                  getAllIsvMatching(params, dispatch, true);
                }, 1000);
              }
            }
          }
        }
      )
      .finally(() => {
        if (!recursion) setloaderMatching(false);
      });
  };

  useEffect(() => {
    getAllIsvMatching(defaultParams, dispatch);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const lunchFilter = () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      page: 1,
    };
    getAllIsvMatching(paramsChange, dispatch);
  };

  const resetFilter = () => {
    resetFilterEvent();

    if (search_name !== "") setSearch_name("");
    if (page !== 1) setPage(1);
    if (sortBy !== "relevance") setSortBy("relevance");

    let paramsChange = {
      ...defaultParams,
      hide_unlocked: "no",
      hide_blacklisted: "no",
      minimumMatch: 60,
      maximumMatch: 100,
      country: "",
      segment: "",
      targeted_industry: "",
      company_size: "",
      annual_turnover: "",
      sortBy,
      orderBy,
      searchCompanyName: "",
      funding_round: "",
      fundraising_goal: "",
      page: 1,
    };
    getAllIsvMatching(paramsChange, dispatch);
  };

  const loadMoreIsvMatching = (p: number) => {
    setPage(p);
    let paramsChange = {
      ...defaultParams,
      page: p,
    };
    getAllIsvMatching(paramsChange, dispatch);
  };

  const handleSearche = () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      searchCompanyName: search_name,
      page: 1,
    };
    getAllIsvMatching(paramsChange, dispatch);
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      handleSearche();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const addFavorite = async (id: number) => {
    let urlFavorite = isOemUser
      ? url?.findIsv?.mark_as_favorite +
        "/" +
        selectedOemProgram?.program?.id +
        "/" +
        id
      : url?.matching_investor_isv?.add_favorie + id;

    let result: any = await patchDataService(urlFavorite, {}, userState?.token);
    if (result?.status === 200) {
      dispatch({ type: ADD_FAVORITE, payload: id });
    }
  };

  const paymentMethodCallBack = (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    if (isOemUser && ProviderOemUnlockIsv?.idIsv)
      oemUnlockIsvPage(isFree, setLoading, setShowPaymentStatus);
    else if (isInvestor && ProviderinvestorUnlockIsv?.idIsv)
      investorUnlockIsvPage(
        isFree,
        paymentMethodId,
        setLoading,
        savePaymentMethod,
        setShowPaymentStatus
      );
    else {
      toast("An error occurred");
      setLoading(false);
      ProviderOemUnlockIsv?.closeUnlockModal(false);
    }
  };

  const oemUnlockIsvPage = async (
    isFree: boolean,
    setLoading: (s: boolean) => void,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    let urlUnlock =
      url?.findIsv?.unlocked +
      "/" +
      selectedOemProgram?.program?.id +
      "/" +
      ProviderOemUnlockIsv?.idIsv +
      (isFree ? "/0" : "/1");

    patchDataService(urlUnlock, {}, userState?.token)
      .then((result) => {
        if (result?.status === 200) {
          dispatch({ type: UNLOCK, payload: result?.data?.matching_unlocked });
          dispatchRedux(
            updateOemProgramBillingDetails(
              result?.data?.oem_program_program_billing_details
            )
          );
          toast("Unlocked successfully");

          goToIsvPage(result?.data?.matching_unlocked?.isv?.id, true);
        } else {
          toast("An Error Occurred");
        }
      })
      .catch(() => {})
      .finally(() => {
        ProviderOemUnlockIsv?.closeUnlockModal();
        setLoading(false);
      });
  };

  const investorUnlockIsvPage = async (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (s: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    let urlUnlocked =
      url?.matching_investor_isv?.unlocked_isv +
      ProviderinvestorUnlockIsv?.idIsv;

    const params = {
      choice: ProviderinvestorUnlockIsv?.choiseValue,
      paymentId: paymentMethodId ?? "",
      savePaymentMethod: savePaymentMethod ?? false,
    };
    patchDataService(urlUnlocked, isInvestor ? params : {}, userState?.token)
      .then((result) => {
        if (result) {
          dispatch({ type: UNLOCK, payload: result?.data?.matching_unlocked });
          dispatchRedux(
            updateOemProgramBillingDetails(
              result?.data?.oem_program_program_billing_details
            )
          );

          if (isFree) {
            toast("Unlocked successfully");
          } else {
            setShowPaymentStatus &&
              setShowPaymentStatus(true, {
                status: "success",
                invoiceNumber: result?.data?.invoice_number,
                invoiceUrl: result?.data?.invoice_url,
              });
          }
        }
      })
      .catch(() => {
        if (isFree) {
          setLoading(false);
          ProviderinvestorUnlockIsv?.setShowPaymentModal(false);
          toast("An Error Occurred");
        } else {
          setShowPaymentStatus &&
            setShowPaymentStatus(true, {
              status: "error",
            });
        }
      })
      .finally(() => {
        ProviderinvestorUnlockIsv?.closeUnlockModal();
        setLoading(false);
      });
  };

  const checkLeftToPayUnlock = async (programId: string, token: string) => {
    const params = {
      programId: programId,
    };
    await checkUnlockPayment(params, token).then((response) => {
      if (response?.status == 200) {
        dispatchRedux(
          handleUpdateAllowUnlockStatus(response?.data?.allow_unlock)
        );
      }
    });
  };

  const unLockedPage = async (id: any) => {
    if (isOemUser)
      await checkLeftToPayUnlock(
        selectedOemProgram?.program?.id,
        userState?.token
      );
    loading && setLoading(false);
    isInvestor && ProviderinvestorUnlockIsv.showModalUnlock(id);
    isOemUser && ProviderOemUnlockIsv.showModalUnlock(id);
  };

  const goToIsvPage = (id: string, isLocked: boolean = false) => {
    if (!isLocked) {
      history.push({
        pathname: "/company/company-unlocked/" + id,
        key :"/company/company-unlocked/" + id,
      });
    } else {
      history.push({
        pathname: "/isv/profil-locked/" + id,
        key: "/isv/profil-locked/" + id,
      });
    }
  };

  const contactIsv = (id: number) => {
    history.push("/contact/discussion", {
      userId: id,
      discussionType:
        userState.roles[0] === OEM_PROGRAM
          ? conversationType.oem_to_isv
          : conversationType.investor_to_isv,
    });
  };

  const isAcceptedMatching = isInvestor || isOemUser;

  useEffect(() => {
    if (isAcceptedMatching || isIsv) {
      dispatchRedux(clearOtherIsvCahe());
    }
  }, []);

  return {
    sliderValue,
    setSliderValue,
    lunchFilter,
    handleHideType,
    selectedHideType,
    selectedCountry,
    handleCountry,
    selectedSegment,
    handleSegment,
    selectedTargeted,
    handleTargeted,
    selectedCompanySize,
    selectedAnnual,
    handleCompanySize,
    handleAnnual,
    filtered,
    handleFilter,
    loaderMatching,
    allIsvMatching,
    loadMoreIsvMatching,
    limit,
    resetFilter,
    addFavorite,
    setSearch_name,
    search_name,
    handleSearche,
    unLockedPage,
    userState,
    handleFundingRound,
    handleFundraising,
    selectedFunding,
    selectedFundraising,
    goToIsvPage,
    contactIsv,
    loading,
    isInvestor,
    isOemUser,
    ProviderOemUnlockIsv,
    ProviderinvestorUnlockIsv,
    isAccountNotActive,
    isAcceptedMatching,
    paymentMethodCallBack,
  };
}
