import moment from "moment";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

interface ISpentBy {
  first_name: string;
  last_name: string;
  user_uri: string;
  number: string;
  at: string;
}

const ResellerPlanDetails = (props: any) => {
  const { free_status } = props;
  const [key, setkey] = useState({
    activeId: "1",
    eventId: "1",
  });

  // render spent by FREE ADS
  const spentBy = (users: Array<ISpentBy>) =>
    users.map((user: ISpentBy) => (
      <>
        {user.number} credit{parseInt(user.number) > 1 ? "s" : ""} spent
        <p key={user.first_name + " " + user.last_name}>
          {/* TODO define the url to redirect user */}
          Spent on {moment(new Date(user.at)).format("MMM DD, YYYY")}
        </p>
        <br />
      </>
    ));
  const formatDate = (date: string) => {
    const newDate = new Date(Date.parse(date));
    return (
      newDate.toLocaleString("en-US", { month: "long" }) +
      " " +
      newDate.getDate() +
      ", " +
      newDate.getFullYear()
    );
  };

  return (
    <div className="contentBorder radius p-0">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 p-4 info">
          <h5>
            {free_status.reseller_classified_ads.number_left} out of{" "}
            {free_status.reseller_classified_ads.number_max}
          </h5>
          <p>Free classified ADS credits available</p>
          <small>
            Next grant: on{" "}
            {formatDate(free_status.reseller_classified_ads.next_grant)}
          </small>
        </div>
      </div>
      <Accordion className="accordionForSetting" activeKey={key.eventId}>
        <div className="lineAccordion">
          {free_status.reseller_classified_ads.spent_by &&
          free_status.reseller_classified_ads.spent_by.length > 0 ? (
            <Accordion.Toggle
              eventKey="1"
              onClick={() => {
                if (key.activeId == "1" && key.activeId == "1") {
                  setkey({ eventId: "0", activeId: "0" });
                } else {
                  setkey({ activeId: "1", eventId: "1" });
                }
                // toggleActive(1);
              }}
              className={`btnAccordion ${key.activeId === "1" ? "active" : ""}`}
            ></Accordion.Toggle>
          ) : (
            <></>
          )}

          <Accordion.Collapse eventKey="1">
            <div className="panel-body row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-12 p-4 info">
                <div className="spent">
                  {spentBy(free_status.reseller_classified_ads.spent_by)}
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default ResellerPlanDetails;
