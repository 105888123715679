import React, { useState, useEffect } from "react";

export interface IListTarget {
  id: number;
  name: string;
  other?: boolean;
}
export default function UseCompleteIndistrueInvestor(props: {
  defaultSelected: Array<IListTarget>;
  defaultDataCompleted?: Array<any>;
}) {
  const [companyTarget, setcompanyTarget] = useState<Array<IListTarget>>([]);
  const [companyTargetSecound, setcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);
  const [lastCompanyTarget, setLastcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);
  const [companyTargetSelected, setCompanyTargetSelected] = useState<
    Array<IListTarget>
  >(props?.defaultSelected || []);

  const [dataCompleted, setDataCompleted] = useState(
    props.defaultDataCompleted ||
      JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );

  const addTargetCompany = (dataMake: IListTarget) => {
    let found = companyTargetSelected?.find(
      (el: IListTarget) => el.id?.toString() === dataMake.id?.toString()
    );
    console.log("dataMakedataMake", dataMake, found);
    if (!found) {
      setCompanyTargetSelected([...companyTargetSelected, dataMake]);
    } else {
      setCompanyTargetSelected(
        companyTargetSelected?.filter(
          (elem: IListTarget) => elem.id?.toString() !== dataMake.id?.toString()
        )
      );
    }
  };

  useEffect(() => {
    async function getTargetCompany() {
      let industrieData = dataCompleted?.targetedIndustry?.filter(
        (el: any) => el.id <= 27
      );
      let divider = Math.floor(industrieData?.length / 3);
      let firstColumn = industrieData?.slice(0, divider);
      let secondColumn = industrieData?.slice(divider, divider * 2);
      let lastComumn = industrieData?.slice(divider * 2);
      setcompanyTarget(firstColumn);
      setcompanyTargetSecond(secondColumn);
      setLastcompanyTargetSecond(lastComumn);
    }
    getTargetCompany();

    return () => {};
  }, [dataCompleted?.targetedIndustry]);

  /**
   * @returns initialise company selected
   */
  useEffect(() => {
    if (props?.defaultSelected?.length > 0) {
      setCompanyTargetSelected(props?.defaultSelected);
    }
    return () => {};
  }, [props?.defaultSelected]);

  const backToDefaultIndustry = () => {
    setCompanyTargetSelected(props?.defaultSelected);
  };

  return {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefaultIndustry,
  };
}
