import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import store from "../redux/store";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  key: string;
  path: string;
  exact: boolean;
  allowedRole?: Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
 
  const { component: Component, isSignedIn, ...rest } = props;

  const isConnected = store.getState().userReducer?.enable 

  const roleConnected =
    store.getState().userReducer && store.getState().userReducer.roles?.[0];

  const isAllowed = props.allowedRole
    ? props.allowedRole?.includes(roleConnected)
    : true;
    
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isConnected && isAllowed ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
          to={{
            pathname: "/",
            state: { from: routeProps.location },
          }}
        />
          // <Redirect
          //   to={{
          //     pathname:
          //       roleConnected
          //         ? "/profil-not-found"
          //         : "/",
          //     state: { from: routeProps.location },
          //   }}
          // />
        )
      }
    />
  );
};

export default PrivateRoute;
