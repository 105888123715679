/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import "./complete-account.css";
import Alert from "react-bootstrap/Alert";
import UseCompleteAccount from "../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-account";
import { ReactComponent as Loader } from "../../../../../resources/images/Double-Ring.svg";
import { useKeycloak } from "@react-keycloak/web";

export default function CompleteAccount() {
  const { params, showLoader, error, resendEmail, resendOdooEmailRequest } =
    UseCompleteAccount();
   const {keycloak} = useKeycloak()
  if (params == undefined) {
    return keycloak.login()
  }

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        {error && (
          <Alert variant="danger">
            An error occurred please try again later
          </Alert>
        )}
        <div className="head-getstarted">
          {params?.sendMail ? (
            <h1>Please confirm your email address.</h1>
          ) : params?.isInvestor ? (
            <h1>Thank you for your form submission.</h1>
          ) : (
            <h1>
              Thank you for registering! Please confirm your email address.
            </h1>
          )}
        </div>
        <div className="contentBorder border-top border-bottom stepfinal">
          <p className="mail">{params?.email}</p>
          {params?.isInvestor ? (
            <p>
              We are currently processing your submission. This usually takes
              less than 48 hours.
              <br />
              We'll be in touch soon!
              <br />
              If you don't receive an email to activate your account within 48
              hours, you can resend your validation request. &nbsp;
              <a
                className="resend-mail"
                onClick={(e) => {
                  e.preventDefault();
                  resendOdooEmailRequest(params?.email);
                }}
              >
                re-send your validation request
                {showLoader && <Loader />}
              </a>
            </p>
          ) : (
            <p>
              Before you can access devinsider we need you to confirm your email
              address.
              <br />
              Please check your inbox for the confirmation email we have sent
              you.
              <br />
              If you have not received the email after a few minutes, <br />
              please check your spam folder or &nbsp;
              <a
                className="resend-mail"
                onClick={(e) => {
                  e.preventDefault();
                  resendEmail();
                }}
              >
                re-send the confirmation email.
                {showLoader && <Loader />}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
