/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import useWelcomeCtr from "../../../controller/use-welcom-ctr";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import {
  checkIsCompletedInvestorCompanyMatching,
  checkIsCompletedOemCompanyMatching,
  fileUrl,
  isModerator,
} from "../../../utils";
import { LoginModal } from "../login/login";
import LoginDirect from "../login/login-direct";
import BlockNotif from "../../widget/block-notif/index";
import { BlockMessage } from "../bloc-message-notif/index";
import { FundingStage } from "../../../interface/compnay-page-investor";
import { Link } from "react-router-dom";
import { detectBrowser } from "../../../service/api";
import { MaMatchmakingUrl } from "../../../service/constant";
import { useKeycloak } from "@react-keycloak/web";
import { useDataContext } from "../../../context";

export default function Header(props: any) {
  const { getStarted, goLogin, showLogin, setShowLogin, started } =
    useWelcomeCtr();

  const { keycloak } = useKeycloak();
  const userState = useSelector((state: rootState) => state.userReducer);
  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const { stopEvtSource } = useDataContext();

  const dispatch = useDispatch();
  const history = useHistory();
  const isConnected = userState?.enable ? true : false;
  const [show, setShow] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);
  //Toogle One

  useEffect(() => {
    const browser = detectBrowser();
    setIsFirefox(browser === "Firefox");
  }, []);

  const showProfil = () => {
    history.push("/profil");
  };

  const goToSettings = () => {
    history.push("/settings", { tabToShow: "d-account" });
  };

  const cb = async (params: boolean) => {
    if (params) {
      stopEvtSource();
      await keycloak.logout({redirectUri:"/"});
    }
  };
  const disconnect = async () => {
    dispatch(logout(cb));
  };

  const goToCompanyPage = () => {
    const isCompanyCompleted =
      userState?.isvInCompanyPage?.company_page?.completed;
    if (isCompanyCompleted) {
      history.push("/company/profil");
    } else {
      history.push("/company/create");
    }
  };

  const goToProgramMatchmaking = () => {
    const url = !isConnected
      ? "/landing-find-prog"
      : !checkIsCompletedOemCompanyMatching(companyPageInfo)
      ? "/program/matchmaking"
      : "/programs/program-matching";
    history.push(url);
  };

  function goToCommunityHome() {
    history.push("/community/home");
  }

  const goToFindInvestor = (stage: FundingStage = "all"): string => {
    if (!keycloak.token && !keycloak.authenticated) {
      return "/landing-find-inv";
    }

    if (!checkIsCompletedInvestorCompanyMatching(companyPageInfo)) {
      return "/find-investors?stage=" + stage;
    } else {
      return "/investor/find-investor-wrapper?stage=" + stage;
    }
  };

  const goDashBoard = () => {
    history.push({
      pathname: "/dashboard",
      key: "key-dashboard",
    });
  };

  const showDiscussion = () => {
    history.push("/contact/discussion");
  };

  const urlPath = window.location.href;
  var valueofSubmenu: any;
  urlPath.indexOf("find-investor") !== -1
    ? (valueofSubmenu = "find-investor")
    : urlPath.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : urlPath?.indexOf("classified-ads") !== -1
    ? (valueofSubmenu = "classified-ads")
    : urlPath?.indexOf("programs") !== -1
    ? (valueofSubmenu = "programs")
    : (valueofSubmenu = "neant");

  const goDashBoardOrWelcome = () => {
    const isConnected = userState?.token;
    if (isConnected) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const isModeratorUser = isModerator(userState.roles);

  return (
    <>
      {isFirefox && (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
          style={{
            position: "fixed",
            top: -10,
            left: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          Devinsider is not compatible with the Firefox browser. Please use
          Chrome, Safari, Edge or another browser that Devinsider is compatible
          with.
        </div>
      )}
      <div
        className="principalMenu"
        style={{ marginTop: isFirefox ? "40px" : "0" }}
      >
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <div className="logoHeader" onClick={() => goDashBoardOrWelcome()}>
              <img src={logoLettre} alt="" style={{ cursor: "pointer" }} />
            </div>
            <div className="menuDynamic tl">
              <div className="item">
                <Link
                  to={{
                    pathname: `${
                      userState?.token ? "/ma/home" : "/landing-find-inv"
                    }`,
                  }}
                  className="parent linkHeader borRight"
                >
                  Corporate development
                </Link>

                <div className="submenu">
                  <div className="title">
                    <div className="container mx-auto">
                      <span>Corporate development</span>
                      Access a curated pool of investors that are looking for
                      software companies to invest in.
                    </div>
                  </div>
                  <div className="container mx-auto flex-wrap">
                    {!isModeratorUser && (
                      <Link
                        to={goToFindInvestor("early")}
                        className="linkSubMenu linkHeader"
                      >
                        <span className="name">Early stage funding</span>
                        <p>
                          Match with early stage software investors based on
                          your company profile and preferences.
                        </p>
                      </Link>
                    )}
                    {!isModeratorUser && (
                      <Link
                        to={goToFindInvestor("later")}
                        className="linkSubMenu linkHeader"
                      >
                        <span className="name">Later stage funding</span>

                        <p>
                          Match with investors to cover funding needs beyond A
                          round.
                        </p>
                      </Link>
                    )}
                    <Link to={"/ma/home"} className="linkSubMenu linkHeader">
                      <span className="name">M&A ads</span>
                      <p>
                        Find exit opportunities among Devinsider’s global
                        community of software vendors and institutional
                        investors.
                      </p>
                    </Link>
                    <Link
                      to={"/alternative-financing/home"}
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Alternative financing ads</span>
                      <p>
                        Identify financing options as an alternative to standard
                        equity investments and loans.
                      </p>
                    </Link>
                    <a
                      href={MaMatchmakingUrl}
                      className="linkSubMenu linkHeader"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="name">M&A Matchmaking</span>
                      <p>
                        Match with buyers and sellers for M&A opportunities
                        globally.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <Link
                  to="/community/home"
                  className={`parent linkHeader borRight ${
                    valueofSubmenu === "community" ? "active" : ""
                  }`}
                >
                  Community
                </Link>

                <div className="submenu">
                  <div className="title">
                    <div className="container mx-auto">
                      <span
                        onClick={() => {
                          goToCommunityHome();
                        }}
                      >
                        Community
                      </span>
                      Join a global community for software vendors (ISVs).
                    </div>
                  </div>
                  <div className="container mx-auto flex-wrap">
                    <Link
                      to="/community/discussions-categories"
                      className="linkHeader"
                    >
                      <span className="name">Forum discussions</span>
                      <p>
                        Discuss the business challenges of your software company
                        with peers and industry experts.
                      </p>
                    </Link>

                    <Link
                      to="/community/article-category/0/0"
                      className="linkHeader"
                    >
                      <span className="name">Articles</span>
                      <p>
                        Share your expertise and insights to help software
                        publishers with high-value content.
                      </p>
                    </Link>

                    <Link
                      to="/community/press-releases/0"
                      className="linkHeader"
                    >
                      <span className="name">Press releases</span>
                      <p>
                        Create exposure to peers, potential investors and tech
                        media outlets.
                      </p>
                    </Link>

                    <Link
                      to="/community/partner-program"
                      className="linkHeader"
                    >
                      <span className="name">Program reviews</span>
                      <p>
                        Review technology partner programs that you have engaged
                        with.
                      </p>
                    </Link>

                    <Link className="linkHeader" to="/events/all-events">
                      <span className="name">Events</span>
                      <p>
                        Browse high-value online and offline events dedicated to
                        software publishers.
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="item">
                <Link
                  className={`parent linkHeader ${
                    valueofSubmenu === "events" ? "active" : ""
                  }`}
                  to={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    goToProgramMatchmaking();
                  }}
                >
                  Strategic partnerships
                </Link>
                <div className="submenu">
                  <div className="title">
                    <div className="container mx-auto">
                      <span>Strategic partnerships</span>
                      Find OEMs and Ecosytems that offer technology partner
                      programs for software publishers (ISVs).
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <Link
                      to="/programs/home"
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Directory</span>
                      <p>
                        Browse an extensive directory of partner programs for
                        software publishers (ISVs).
                      </p>
                    </Link>
                    <Link
                      to="/program/matchmaking"
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Partner programs</span>
                      <p>
                        Match with OEM and Ecosystem partner programs based on
                        your company profile and preferences.
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rightItemHeader">
            {keycloak && keycloak.token ? (
              <div className="menuDynamic tr">
                <>
                  <div className="item">
                    <a
                      href="javascript:;"
                      className="linkHeader"
                      onClick={(e: any) => {
                        e?.preventDefault();
                        goDashBoard();
                      }}
                    >
                      <span className="icon-home"></span>
                      <span className="mobile">Home</span>
                    </a>
                  </div>
                  <div className="item" onClick={() => showDiscussion()}>
                    <BlockMessage unread={props.unread} />
                  </div>
                  <BlockNotif />
                </>

                <div className="item">
                  <a href="javascript:;" className="parent linkHeader">
                    <div className={`profilImg`}>
                      {userState.file_name && (
                        <img
                          alt="Profile"
                          src={`${fileUrl(userState.file_name)}`}
                        />
                      )}
                    </div>
                    <span className="mobile">My account</span>
                  </a>
                  <div className="submenu">
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        showProfil();
                      }}
                    >
                      Profile
                    </a>
                    {!isModerator(userState.roles) && (
                      <>
                        <a
                          href="javascript:;"
                          onClick={(e) => {
                            e.preventDefault();
                            goToCompanyPage();
                          }}
                        >
                          Company page
                        </a>
                        <a
                          href="javascript:;"
                          onClick={(e) => {
                            e.preventDefault();
                            goToSettings();
                          }}
                        >
                          Account settings
                        </a>
                      </>
                    )}

                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        disconnect();
                      }}
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="menuDynamic tr notConnected">
                <div className="item">
                  <a
                    href="javascript:;"
                    className="signinHeader getstartedHeader"
                    onClick={(e) => {
                      e.preventDefault();
                      goLogin();
                    }}
                  >
                    Sign in
                  </a>
                </div>
                <div className="item">
                  <a
                    href="javascript:;"
                    className="getstartedHeader orange"
                    onClick={(e) => {
                      e.preventDefault();
                      getStarted();
                    }}
                  >
                    Get started
                  </a>
                </div>
              </div>
            )}
          </div>
          <LoginModal
            urlType={urlPath.indexOf("community") !== -1 ? "community" : ""}
            show={showLogin}
            setShow={setShowLogin}
            goLogin={goLogin}
            getStarted={getStarted}
            started={started}
          />
          <LoginDirect show={show} setShow={setShow} />
        </div>
      </div>
    </>
  );
}
