/* eslint-disable jsx-a11y/anchor-is-valid */
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import UseCommunityPRCtr from "../../../../controller/community-ctr/bloc-community-ctr/use-press-release-ctr";
import UseGlogalBilling from "../../../../controller/settings-ctr/billing-center/use-global-billing";
import { rootState } from "../../../../redux/reducers";

import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  stripePrivateKey,
} from "../../../../service/constant";
import { isModerator } from "../../../../utils";

import ModalContactUs from "../../../widget/contact-us-widget/index";
import UseContactUsWidget from "../../../widget/contact-us-widget/use-contact-us-widget";
import { PaymentPopup } from "../../payment-popup/payment-popup";

export type IPropsModal = {
  show: boolean;
  setShow: (p: boolean) => void;
  activeCategorie: number;
  setactiveCategorie: (p: number) => void;
  createPost: (p: number) => void;
  role: string[];
  billingDetail?: any;
  setShowModalPost?: any;
};

const postNonCompatibleRole = (role: string, postType: string) => {
  var nonCompatible = false;

  if (
    (role === OEM_PROGRAM ||
      role === INVESTOR ||
      role === RESELLER ||
      role === CONTRIBUTOR) &&
    postType === "Partner Program Review"
  ) {
    nonCompatible = true;
  }
  if (
    (role === ISV ||
      role === INVESTOR ||
      role === RESELLER ||
      role === CONTRIBUTOR) &&
    postType === "Featured Article"
  ) {
    nonCompatible = true;
  }
  if (
    (role === INVESTOR || role === RESELLER || role === CONTRIBUTOR) &&
    postType === "Press Release"
  ) {
    nonCompatible = true;
  }

  return nonCompatible;
};

export default function ModalStartPost(props: IPropsModal) {
  const providerUseContactUsWidget = UseContactUsWidget();

  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const user = useSelector((state: rootState) => state.userReducer);

  let stripePromise: Stripe | PromiseLike<Stripe | null> | null = null;
  if (user.token) {
    stripePromise = loadStripe(stripePrivateKey);
  }

  const isOem = props?.role?.[0]?.toString() === OEM_PROGRAM;
  const isIsv = props?.role?.[0]?.toString() === ISV;
  const [noAccessFeatured, setNoAccessFeatured] = useState<boolean>(false);
  const [noAccessPress, setNoAccessPress] = useState<boolean>(false);
  const [noFeatured, setNoFeatured] = useState<boolean>(false);
  const [noPress, setNoPress] = useState<boolean>(false);
  const [errorAffiliated, setErrorAffiliated] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [product, setProduct] = useState<{
    lookupKey: string;
    name: string;
  }>();

  const goContactUs = () => {
    providerUseContactUsWidget?.setIsShowContactSupport(true);
    props?.setShow(false);
  };

  const goToPaymentModal = (activeCategorie: any) => {
    if (activeCategorie === 3) {
      setProduct({
        lookupKey: "PRESS_RELEASE",
        name: "press release",
      });
    } else {
      setProduct({
        lookupKey: "FEATURED_ARTICLE",
        name: "featured article",
      });
    }

    setShowPaymentModal(true);
  };

  const renderTextFeaturedArticleOem = () => {
    if (noAccessFeatured) {
      return (
        <label>
          This is a premium feature, upgrade your plan to publish a featured
          article.
        </label>
      );
    }
    if (noAccessPress) {
      return (
        <label>
          This is a premium feature, upgrade your plan to publish a press
          release.
        </label>
      );
    }
    if (noPress) {
      return (
        <label>
          You have reached the maximum amount of press releases within your
          plan.{" "}
          <a style={{ color: "#2a9df4" }} onClick={() => goContactUs()}>
            Contact us
          </a>{" "}
          to customize your plan.
        </label>
      );
    }
    if (noFeatured) {
      return (
        <label>
          You have reached the maximum amount of featured articles within your
          plan.{" "}
          <a style={{ color: "#2a9df4" }} onClick={() => goContactUs()}>
            Contact us
          </a>{" "}
          to customize your plan.
        </label>
      );
    }
    return <></>;
  };

  const renderTypeDiscussion = (index: number, el: any) => {
    return (
      <>
        <a
          className={`choicePost ${
            props?.activeCategorie === el.id ? "active" : ""
          }`}
          onClick={(e: any) => {
            if (
              isOem &&
              oemProgram.oem_subscription.id === 1 &&
              el.type === "Press Release"
            ) {
              e?.preventDefault();
              props?.setactiveCategorie(el.id);
              setNoAccessPress(true);
              setNoAccessFeatured(false);
              setNoFeatured(false);
              setNoPress(false);
            } else if (
              isOem &&
              oemProgram.oem_subscription.id === 1 &&
              el.type === "Featured Article"
            ) {
              props?.setactiveCategorie(el.id);
              setNoAccessPress(false);
              setNoAccessFeatured(true);
              setNoFeatured(false);
              setNoPress(false);
            } else if (
              isOem &&
              oemProgram.oem_subscription.id !== 1 &&
              el.type === "Press Release" &&
              props?.billingDetail?.free_status?.oem_press_release
                ?.number_left < 1
            ) {
              e?.preventDefault();
              props?.setactiveCategorie(el.id);
              setNoAccessPress(false);
              setNoAccessFeatured(false);
              setNoPress(true);
              setNoFeatured(false);
            } else if (
              isOem &&
              oemProgram.oem_subscription.id !== 1 &&
              el.type === "Featured Article" &&
              props?.billingDetail?.free_status?.oem_program_featured_article
                ?.number_left < 1
            ) {
              e?.preventDefault();
              props?.setactiveCategorie(el.id);
              setNoAccessPress(false);
              setNoAccessFeatured(false);
              setNoPress(false);
              setNoFeatured(true);
            } else {
              e?.preventDefault();
              props?.setactiveCategorie(el.id);
              setNoAccessPress(false);
              setNoAccessFeatured(false);
              setNoFeatured(false);
              setNoPress(false);
            }
          }}
        >
          <span className={el.icon} />
          {el?.type.toLowerCase()}
        </a>
      </>
    );
  };

  const handleSubButton = () => {
    if (Number(props?.activeCategorie) === 5) {
      if (
        isOem &&
        user?.oemProgramMembers.filter(
          (oemp) => oemp.oem_program.id === oemProgram.id
        ).length < 1
      ) {
        setErrorAffiliated(true);
        props?.setShow(false);
      } else if (
        isOem &&
        user?.oemProgramMembers.filter(
          (oemp) => oemp.oem_program.id === oemProgram.id
        ).length > 0
      ) {
        goToPaymentModal(Number(props?.activeCategorie));
        props?.setShow(false);
      } else if (isModerator(props?.role)) {
        props?.createPost(Number(props?.activeCategorie));
      }
    }
    if (Number(props?.activeCategorie) === 3) {
      if (isIsv && !user?.isvInCompanyPage) {
        setErrorAffiliated(true);
        props?.setShow(false);
      } else if (isIsv && user?.isvInCompanyPage) {
        goToPaymentModal(Number(props?.activeCategorie));
        props?.setShow(false);
      } else if (
        isOem &&
        user?.oemProgramMembers.filter(
          (oemp) => oemp.oem_program.id === oemProgram.id
        ).length < 1
      ) {
        setErrorAffiliated(true);
        props?.setShow(false);
      } else if (
        isOem &&
        user?.oemProgramMembers.filter(
          (oemp) => oemp.oem_program.id === oemProgram.id
        ).length > 0
      ) {
        goToPaymentModal(Number(props?.activeCategorie));
        props?.setShow(false);
      } else if (isModerator(props?.role)) {
        props?.createPost(Number(props?.activeCategorie));
      }
    }
    if (
      Number(props?.activeCategorie) !== 3 &&
      Number(props?.activeCategorie) !== 5
    ) {
      if (Number(props?.activeCategorie) === 4) {
        if (!user?.isvInCompanyPage) {
          setErrorAffiliated(true);
          props?.setShow(false);
        } else {
          props?.createPost(Number(props?.activeCategorie));
        }
      } else {
        props?.createPost(Number(props?.activeCategorie));
      }
    }
  };

  const paymentMethodCallBack = () => {
    props?.createPost(Number(props?.activeCategorie));
  };

  return (
    <>
      {user.token && (
        <Elements stripe={stripePromise}>
          <Modal
            show={props?.show}
            className="createCompanypopup large forStep5custom"
          >
            <Modal.Header>
              <div className="headerpopupCompany noStep">
                <h2 className="title">Start a post</h2>
                <a
                  href="javascript:;"
                  className="close"
                  onClick={() => {
                    props?.setShow(false);
                    // props.setShowModalPost && props.setShowModalPost(false);
                  }}
                >
                  <span className="icon-close-pop"></span>
                </a>
              </div>
            </Modal.Header>
            {!props?.billingDetail ? (
              "Loading..."
            ) : (
              <>
                <Modal.Body className="">
                  <div className="contentBorder row forPitch choicePostcontainer">
                    <label htmlFor="">Select a type of message :</label>
                    <div className="listPost">
                      {communityReducer?.postType?.map(
                        (
                          el: { id: number; type: string; icon: string },
                          index: number
                        ) => (
                          <div key={el.id}>
                            {postNonCompatibleRole(
                              props?.role[0]?.toString(),
                              el?.type
                            ) ? (
                              <></>
                            ) : (
                              renderTypeDiscussion(index, el)
                            )}
                          </div>
                        )
                      )}
                    </div>
                    {renderTextFeaturedArticleOem()}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="contentBorder formForEmail text-right">
                    <button
                      className="btn btn-devinsider cancel"
                      onClick={() => {
                        props?.setactiveCategorie(0);
                        props?.setShow(false);
                      }}
                    >
                      Cancel
                    </button>
                    {noAccessFeatured ||
                    noAccessPress ||
                    noPress ||
                    noFeatured ? (
                      <button
                        className="btn btn-devinsider disabled cancel"
                        style={{
                          cursor: "no-drop",
                        }}
                        onClick={() => {}}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className="btn btn-devinsider px-5 save"
                        onClick={handleSubButton}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </Modal.Footer>
              </>
            )}
          </Modal>
        </Elements>
      )}
      <ModalContactUs {...providerUseContactUsWidget} required />

      <PaymentPopup
        show={showPaymentModal}
        title={
          isIsv
            ? "Post press release"
            : isOem && Number(props?.activeCategorie) === 5
            ? "Post featured article"
            : isOem && Number(props?.activeCategorie) === 3
            ? "Post press release"
            : ""
        }
        setShowPaymentModal={setShowPaymentModal}
        product={product}
        priceInfoCallback={paymentMethodCallBack}
        free={
          isIsv
            ? props?.billingDetail?.free_status?.isv_press_release?.number_left
            : isOem && Number(props?.activeCategorie) === 5
            ? props?.billingDetail?.free_status?.oem_program_featured_article
                ?.number_left
            : isOem && Number(props?.activeCategorie) === 3
            ? props?.billingDetail?.free_status?.oem_press_release?.number_left
            : 0
        }
        isPriceInfo={true}
      />

      {/*Popup Permission*/}
      <Modal show={errorAffiliated} className="small forDesactivate">
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Oops...</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => setErrorAffiliated(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <h3 className="title withIcon">
              <span className="icon-info"></span>
            </h3>
            {props?.activeCategorie === 3 ? (
              <p>
                You must be affiliated to a company page in order to publish a
                program review
              </p>
            ) : (
              <p>
                You must be affiliated to a company page in order to publish a
                press release
              </p>
            )}
            <p></p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
