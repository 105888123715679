import DetailAlternativeCtr from "../../../../controller/alternative-ctr/detail-alternative-ctr";
import React, { useState } from "react";
import Header from "../../../widget/index";
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  fileUrl,
  getDescriptionEditorState,
  isModerator,
  paginate,
} from "../../../../utils";
import SeeBtn from "../../mergers-acquisitions/SeeBtn";
import { NavLink } from "react-router-dom";
import { LoadingAllPage } from "../../../widget/loader";
import { LoginModal } from "../../../widget/login/login";

export default function DetailAlternative() {
  const provider = DetailAlternativeCtr();

  const [show, setShow] = useState(false);
  const dataSolution = [
    ...(provider?.alternativeReducer?.software_solution || []),
    ...(provider?.alternativeReducer?.featured_solution || []),
  ];

  const formatNumber = (number: number | null | undefined) => {
    if (number !== null && number !== undefined) {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(0) + "M";
      } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + "K";
      } else {
        return number.toString();
      }
    }
  };
  return (
    <>
      <Header />

      {provider.showLoading && <LoadingAllPage />}

      <section className="classified mergers details">
        <div className="container mx-auto">
          <div className="leftSection">
            <div className="block">
              <div className="leftItem itemAuthor">
                <div className="imageAffiliatedcompany empty">
                  <img
                    src={fileUrl(provider?.alternativeReducer?.user?.file_name)}
                    alt=""
                  />
                </div>
                <div className="">
                  <div className="post">Author:</div>
                  <h3 className="title">
                    {provider?.alternativeReducer?.user?.first_name}
                  </h3>
                  <h3 className="title">
                    {provider?.alternativeReducer?.user?.last_name}
                  </h3>
                </div>
              </div>
            </div>
            <div className="block infoCompanyAds">
              <div className="imgclassified empty">
                <img src={fileUrl(provider?.alternativeReducer?.logo)} alt="" />
              </div>
              <div className="infoAds">
                Company:
                <h3 className="title">
                  {provider?.alternativeReducer?.company_name}
                </h3>
                {/*<div className="role">ISV</div>*/}
                <div className="contry">
                  {provider?.alternativeReducer?.country?.country_name}
                </div>
              </div>
            </div>
            <div className="block solInd">
              <h3 className="title">Solution</h3>
              {paginate(dataSolution, provider.limitSolution)?.map(
                (el: any, index: number) => (
                  <div key={index}>{el?.name}</div>
                )
              )}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeSolution(p)}
                dataSource={dataSolution}
                limit={provider.limitSolution}
              />
            </div>
            <div className="block solInd">
              <h3 className="title">Industry</h3>
              {paginate(
                provider?.alternativeReducer?.targeted_industry,
                provider.limitIndustry
              )?.map((el: any, index: number) => (
                <div key={index}>{el?.name}</div>
              ))}
              <SeeBtn
                seeMoreOrLess={(p: string) => provider.handleSeeIndustry(p)}
                dataSource={
                  provider?.alternativeReducer?.targeted_industry || []
                }
                limit={provider.limitIndustry}
              />
            </div>
            <div className="block solInd">
              <h3 className="title">Funding target</h3>
              {provider?.alternativeReducer?.annual_raise_max !== null &&
                provider?.alternativeReducer?.annual_raise_min !== null && (
                  <div>
                    {formatNumber(
                      provider?.alternativeReducer?.annual_raise_min
                    ) +
                      " to " +
                      formatNumber(
                        provider?.alternativeReducer?.annual_raise_max
                      ) +
                      " USD"}
                  </div>
                )}
            </div>
          </div>
          <div className="rightSection ads">
            <h3>{provider?.alternativeReducer?.title}</h3>
            <ul className={`btn `}>
              {!isModerator(provider.userReducer?.roles) && (
                <>
                  <li
                    className={`${
                      provider.isDisabled(
                        provider?.alternativeReducer?.hasMessage
                      )
                        ? "disabled-ma-btn"
                        : ""
                    }`}
                    style={{
                      display:
                        provider?.alternativeReducer?.hasMessage === false &&
                        provider.isMyPost
                          ? "none"
                          : "block",
                    }}
                    onClick={() =>
                      provider.sendMessage(
                        provider?.alternativeReducer?.hasMessage
                      )
                    }
                  >
                    {provider.isMyPost ? "View all Messages" : "Send a Message"}
                  </li>
                </>
              )}
              <li
                className="pl-2 pr-2"
                onClick={() => provider.handleAddFavorite()}
              >
                <span
                  className={
                    provider?.alternativeReducer?.liked
                      ? "icon-fav-full"
                      : "icon-fav-line"
                  }
                />
              </li>
              {provider.isMyPost && (
                <li className="pl-1 pr-1 collpasible">
                  <NavLink to="#">
                    <span className="icon-inprogress"></span>
                  </NavLink>

                  <ul className="editListing">
                    <li onClick={() => provider.editPost()}>Edit listing</li>
                    <li onClick={() => provider.deletePost()}>Delete</li>
                  </ul>
                </li>
              )}
            </ul>
            <div
              className="detailsAds"
              dangerouslySetInnerHTML={createMarkup(
                convertCommentFromJSONToHTML(
                  getDescriptionEditorState(
                    provider?.alternativeReducer?.content
                  )
                )
              )}
            />
          </div>
        </div>
        <LoginModal
          urlType={"ma"}
          show={provider?.showLogin}
          setShow={provider?.setShowLogin}
        />
      </section>
    </>
  );
}
