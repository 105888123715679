import React, { useState, useRef } from "react";
import {
  updateDraftDataAction,
  removeLogoInvestorPage,
  removeCouvertureInvestorPage,
} from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useParams } from "react-router-dom";
import { url } from "../../../service/constant";
import { postDataFormDataService } from "../../../service/applicatif/back-end-service";

export const UPDATE_OR_PUBLISH_DRAFT__DATA = "UPDATE_OR_PUBLISH_DRAFT__DATA";

export default function useEditPhotoBackGround() {
  const dispatch = useDispatch();

  const [isShowBackGround, setShowUpdateBackGround] = useState<boolean>(false);
  const [isShowProfil, setShowUpdateProfile] = useState<boolean>(false);
  const fileRefProfil: React.RefObject<HTMLInputElement> = useRef(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedFilePicture, setSelectedFilePicture] = useState<File>();
  const [isFilePickedPicture, setIsFilePickedPicture] = useState(false);
  const [showConfirmationRemoveLogo, setShowConfirmationRemoveLogo] =
    useState<boolean>(false);
  const [
    showConfirmationRemoveCouverture,
    setShowConfirmationRemoveCouverture,
  ] = useState<boolean>(false);
  const [processingRemoveLogo, setProcessingRemoveLogo] =
    useState<boolean>(false);
  const [hasRemovedPicture, setHasRemovedPicture] = useState<boolean>(false);
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const companyPageInvestor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );

  let userReducer = useSelector((state: rootState) => state.userReducer);

  const profilUrl =
    companyPageInvestor?.investor_type?.id == 1
      ? companyPageInvestor?.photo_profil
      : isOtherUser
      ? companyPageInvestor?.investorMembers?.[0]?.user?.file_name
      : userReducer?.file_name;

  const changeHandlerProfil = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const isImage =
        event?.target?.files[0] &&
        event?.target?.files[0]?.type?.includes("image");
      if (isImage) {
        setSelectedFilePicture(event?.target?.files[0]);
        setIsFilePickedPicture(true);
      }
    }
  };

  const restorePhotoChange = () => {
    setSelectedFilePicture(undefined);
    if (fileRefProfil.current) {
      fileRefProfil.current.value = "";
    }
  };

  const cbUpdateProfile = (data: any) => {
    setLoader(false);
    hideSelectedFilePicture();
    dispatch({
      type: UPDATE_OR_PUBLISH_DRAFT__DATA,
      payload: {
        isupdated: true,
      },
    });
  };

  const updateProfilPicture = () => {
    let params = {
      photoProfileDraft: selectedFilePicture,
    };
    let paramsBackGroung = {
      photoCoverDraft: selectedFilePicture,
    };
    setLoader(true);
    dispatch(
      updateDraftDataAction(
        isShowProfil ? params : paramsBackGroung,
        userReducer?.investor_members?.investor_page?.id,
        cbUpdateProfile
      )
    );
  };
  const hideSelectedFilePicture = () => {
    setSelectedFilePicture(undefined);
    if (isShowProfil) {
      setShowUpdateProfile(false);
    }
    if (isShowBackGround) {
      setShowUpdateBackGround(false);
    }
  };

  const handleBtnPicture = () => {
    if (fileRefProfil) {
      fileRefProfil?.current?.click();
      //  setIsFilePicked(false);
    }
  };

  const handleRemoveLogoInvestorPage = async () => {
    setProcessingRemoveLogo(true);
    //update draft logo
    const rep = await postDataFormDataService(
      {
        _photoProfil: "1",
      },
      url.investor_company_page.url_update_investor_page +
        companyPageInvestor.id,
      userReducer.token
    );
    if (rep?.status === 200) {
      //update redux
      dispatch(
        removeLogoInvestorPage({
          photo_profil_draft: rep?.data?.photo_profil_draft,
          photo_profil: rep?.data?.photo_profil,
        })
      );
      dispatch({
        type: UPDATE_OR_PUBLISH_DRAFT__DATA,
        payload: {
          isupdated: true,
        },
      });
      setShowConfirmationRemoveLogo(false);
    }
    setProcessingRemoveLogo(false);
  };

  const handleRemoveCouvertureInvestorPage = async () => {
    setProcessingRemoveLogo(true);
    //update draft logo
    const rep = await postDataFormDataService(
      {
        _photoCouverture: "1",
      },
      url.investor_company_page.url_update_investor_page +
        companyPageInvestor.id,
      userReducer.token
    );
    if (rep?.status === 200) {
      //update redux
      dispatch(
        removeCouvertureInvestorPage({
          photo_couverture_draft: rep?.data?.photo_couverture_draft,
          photo_couverture: rep?.data?.photo_couverture,
        })
      );
      dispatch({
        type: UPDATE_OR_PUBLISH_DRAFT__DATA,
        payload: {
          isupdated: true,
        },
      });
      setShowConfirmationRemoveCouverture(false);
    }
    setProcessingRemoveLogo(false);
  };

  return {
    isShowBackGround,
    setShowUpdateBackGround,
    isShowProfil,
    setShowUpdateProfile,
    fileRefProfil,
    changeHandlerProfil,
    isFilePickedPicture,
    selectedFilePicture,
    restorePhotoChange,
    loader,
    updateProfilPicture,
    hideSelectedFilePicture,
    handleBtnPicture,
    photo_profil: companyPageInvestor?.photo_profil_draft
      ? companyPageInvestor?.photo_profil_draft
      : profilUrl,
    photo_couverture: companyPageInvestor?.photo_cover_draft
      ? companyPageInvestor?.photo_cover_draft
      : companyPageInvestor?.photo_couverture,
    isIndividuelCp: companyPageInvestor?.investor_type?.id == 2,
    showConfirmationRemoveLogo,
    showConfirmationRemoveCouverture,
    setShowConfirmationRemoveLogo,
    setShowConfirmationRemoveCouverture,
    handleRemoveLogoInvestorPage,
    handleRemoveCouvertureInvestorPage,
    processingRemoveLogo,
    hasRemovedPicture,
  };
}
