import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReplyByUserCtr from "../../../../../controller/community-ctr/activity-user/replies-by-user-ctr";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
import { calculateDate, dateDiff, fileUrl } from "../../../../../utils";

const RepliesByUser = (props: any) => {
  const providerReplyUser = ReplyByUserCtr(props?.userId);

  const renderData = (userPost: Array<any>) => {
    return userPost?.map((post: any) => (
      <li key={post?.id}>
        <div className="infoAbout">
          <div className="withPost">
            <div className="imageAffiliatedcompany empty">
              {post?.user?.file_name && (
                <img src={fileUrl(post?.user?.file_name)} alt="" />
              )}
            </div>
            <div
              className={
                "nameOfPost " + props?.getBadge(post?.user?.roles).badge
              }
            ></div>
          </div>
          <div className="infoFeed">
            <div
              className="titlePost"
              onClick={() =>
                providerReplyUser?.goToreplayPost(
                  post?.id,
                  post?.category?.id || 0
                )
              }
            >
              {post?.solve && <span className="icon-check" />}
              {post?.post_type?.id?.toString() === "2" && (
                <span className="article">Articles</span>
              )}
              <span className="title">{"Re: " + post?.title} </span>
            </div>
            <div className="postBy">
              by{" "}
              <Link to={"/community/activity-user/" + post?.user?.id}>
                {post?.user?.first_name + " " + post?.user?.last_name}
              </Link>{" "}
              on {moment(post?.created_at).format("MM-DD-Y hh:mm A")}
            </div>
            <div className="postBy">
              Latest activity {dateDiff(post?.updated_at)} by
              <Link
                to={"/community/activity-user/" + post?.last_activity_by?.id}
              >
                {" "}
                {post?.last_activity_by?.first_name +
                  " " +
                  post?.last_activity_by?.last_name}
              </Link>
            </div>
            <div className="subCategory">
              {post?.labels?.map((label: any) => (
                <span
                  key={label.id}
                  onClick={() =>
                    props.showLabel(
                      post?.category?.id?.toString(),
                      label.id,
                      post?.post_type?.id
                    )
                  }
                >
                  {" "}
                  {label.title}{" "}
                </span>
              ))}
            </div>
            <div className="subCategory">
              {post?.tags?.map((tag: any) => (
                <span
                  key={tag.id}
                  onClick={() =>
                    props.showLabel("0", tag.id, post?.post_type?.id)
                  }
                >
                  {" "}
                  {tag.name}{" "}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="replyInfo">
          <b> {post?.numberOfReplies} </b>
          {parseInt(post?.numberOfReplies) > 1 ? "Replies" : "Reply"}
        </div>
        <div className="viewInfo">
          <b> {post?.numberOfViews} </b>
          {parseInt(post?.numberOfViews) > 1 ? "Views" : "View"}
        </div>
      </li>
    ));
  };

  return providerReplyUser?.repliesByUser?.results?.length > 0 ? (
    <>
      <div className="titleCategory">
        <Select
          onChange={(e: any) => providerReplyUser.handleChangePostType(e)}
          className="triOreder nameOfTitle"
          isClearable={false}
          options={[
            { value: "1", label: "View forum Discussions only" },
            { value: "2", label: "View articles only" },
            { value: "", label: "All replies" },
          ]}
          classNamePrefix="tri"
          defaultValue={{
            value: "",
            label: "All replies",
          }}
        />
        <div className="tri">
          <div className="labelSelect">
            <label>Show:</label>
            <Select
              onChange={(e: any) => providerReplyUser.handleChangeShow(e)}
              className="triNumber"
              isClearable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              classNamePrefix="tri"
              defaultValue={{ value: "10", label: "10" }}
              value={{
                value: providerReplyUser.repliesUserFilter.limit,
                label: providerReplyUser.repliesUserFilter.limit,
              }}
            />
          </div>

          <div className="sortedby labelSelect">
            <label>Sorted by :</label>
            <Select
              onChange={(e: any) => providerReplyUser.handleChangeSorted(e)}
              className="triOreder"
              isClearable={false}
              options={[
                { value: "post_date", label: "Post date" },
                { value: "activity", label: "Latest activity" },
                { value: "replies", label: "Replies" },
                { value: "views", label: "Views" },
              ]}
              classNamePrefix="tri"
              defaultValue={{
                value: "post_date",
                label: "Post date",
              }}
            />
          </div>
        </div>
      </div>
      <ul className="feedList categoryList">
        {providerReplyUser?.loadingReplies ? (
          <LoadingArticle />
        ) : (
          renderData(providerReplyUser?.repliesByUser?.results)
        )}
      </ul>
      <div className="pagginationCategory">
        <div className="labelSelect">
          <label>Show:</label>
          <Select
            /*menuIsOpen={true}*/
            onChange={(e: any) => {
              providerReplyUser.handleChangeShow(e);
            }}
            className="triNumber"
            isShow
            isClearable={false}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
            ]}
            classNamePrefix="tri"
            defaultValue={{ value: "10", label: "10" }}
            value={{
              value: providerReplyUser.repliesUserFilter.limit,
              label: providerReplyUser.repliesUserFilter.limit,
            }}
          />
        </div>
        <div className="pagination">
          <Pagination
            totalCount={providerReplyUser?.repliesByUser?.total}
            currentPage={providerReplyUser?.repliesUserFilter?.page * 1}
            className="pagination"
            idContainer="repliesByUser"
            pageSize={providerReplyUser?.repliesUserFilter?.limit}
            onPageChange={(p: any) => providerReplyUser?.handlePage(p)}
          />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default RepliesByUser;
