//ROUTES FOR CATEGORIES
import { routesIsv } from "./isv-routes";
import { routesReseller } from "./reseller-routes";
import { routesExpert } from "./expert-routes";
import { routesGuest } from "./guest-routes";
import { routesProfils } from "./profils-routes";
import { routesCommunity } from "./community-routes";
import { routesEvents } from "./events-routes";
import { routesMA } from "./mergers-acquisitions-routes";
import { routesSoftwareLabeling } from "./software-labeling-routes";
import { routesBS } from "./business-services-routes";
import { routesCorporate } from "./corporate-routes";
import { routesSponsors } from "./sponsors-routes";
import { routesRecruitement } from "./recruitement-routes";
import { routesAF } from "./alternative-financing-routes";

// Route Views
import Welcome from "../screen/container/welcome";
import AccountChoise from "../screen/container/acount/account-choix/account-choise";
import PartnerProgram from "../screen/container/acount/complete-create-account-partner-program/partner-program";
import ForgotPassword from "../screen/container/acount/forgot-password/forgot-password";
import ResetPassword from "../screen/container/acount/reset-password/reset-password";
import CompleteAffiliated from "../screen/container/acount/create-affiliated-account/index";

import { routesInvestors } from "./investor-routes";
import { discussionRoute } from "./discussion";
import { routeSettings } from "./settings-routes";
import { routesCompany } from "./company-routes";
import { routesBillingCenter } from "./billing-center";
import { routesOEM } from "./oem-routes";
import { routesPrograms } from "./programs-routes";
import { routesContact } from "./contact-routes";
import { aboutRoute } from "./about-routes";

import DashboardPage from "../screen/container/dashboard/dashboard";
import NotFound from "../screen/container/not-found/not-found";
import CommunityGuidelines from "../screen/container/static-page/community-guidelines";
import TermOfUse from "../screen/container/static-page/terms-of-use";
import PrivacyPolicy from "../screen/container/static-page/privacy-policy";
import { routesNotifications } from "./notifications-routes";

// public routes
import { unsubscribeContact } from "./public-routes";
import { routesMarketplace } from "./marketplace-routes";
import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from "../service/constant";
import { lazy } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    component: Welcome,
    guard: "public",
  },
  {
    path: "/community-guidelines",
    exact: true,
    component: CommunityGuidelines,
    guard: "public",
  },
  {
    path: "/term-of-use",
    exact: true,
    component: TermOfUse,
    guard: "public",
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    component: TermOfUse,
    guard: "public",
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy,
    guard: "public",
  },
  {
    path: "/signin",
    guard: "public",
    exact: true,
    component: lazy(
      () => import("../screen/container/acount/signin/RedirectPage")
    ),
  },
  {
    path: "/check-user-info-user",
    guard: "public",
    exact: true,
    component: lazy(
      () => import("../screen/container/acount/signin/CheckUserInfo")
    ),
  },

  {
    path: "/account-choices",
    guard: "public",
    exact: true,
    component: AccountChoise,
  },
  {
    path: "/affiliated-info",
    guard: "not-logged-page",
    exact: true,
    component: CompleteAffiliated,
    allowedRole: [ISV, OEM_PROGRAM],
  },
  {
    path: "/become_verified",
    guard: "public",
    allowedRole: [ISV, OEM_PROGRAM],
    exact: true,
    component: CompleteAffiliated,
  },
  ...routesIsv,
  ...routesReseller,
  ...routesInvestors,
  ...routesExpert,
  ...routesGuest,
  ...routesProfils,
  ...routeSettings,
  ...routesCompany,
  ...routesBillingCenter,
  ...routesOEM,
  ...routesCommunity,
  ...routesEvents,
  ...routesPrograms,
  ...discussionRoute,
  ...aboutRoute,
  ...routesContact,
  ...routesNotifications,
  ...unsubscribeContact,
  ...routesMA,
  ...routesSoftwareLabeling,
  ...routesBS,
  ...routesMarketplace,
  ...routesCorporate,
  ...routesSponsors,
  ...routesRecruitement,
  ...routesAF,
  //...routesClassifiedAds,
  // Route to partner program registration
  {
    path: "/partner-program-registration",
    guard: "not-logged-page",
    exact: true,
    component: PartnerProgram,
    allowedRole: [OEM_PROGRAM],
  },

  {
    path: "/forgot-password",
    guard: "not-logged-page",
    exact: true,
    component: ForgotPassword,
  },

  {
    path: "/password_recovery",
    guard: "public",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/privacy",
    guard: "public",
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: "/dashboard",
    guard: "private",
    component: DashboardPage,
    exact: true,
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },

  {
    path: "/profil-not-found",
    guard: "public",
    exact: true,
    component: NotFound,
  },
  {
    path: "*",
    guard: "public",
    exact: true,
    component: NotFound,
  },
];
