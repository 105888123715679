import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import { PaymentBrands } from '../payment-card/payment-brands';
import { PaymentCard } from '../payment-card/payment-card';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { BtnNext } from '../../../widget/bouton/btn-next';
import { PaymentInfoProps } from './payment-info-wrapper';
import { LoadingFeed } from '../../../widget/loader';
import {PaymentBillingAddress} from "../payment-billing-address/payment-billing-address";

export type PaymentInputErrors = {
  firstname: boolean;
  lastname: boolean;
  billingAddress: boolean;
  companyName: boolean;
  vatNumber?: boolean;
}

export type BillingAddress = {
  country: string,
  city: string,
  line1: string,
  state: string,
  postal_code: string,
}

export const PaymentInfo: React.FC<PaymentInfoProps> = ({
  product,
  currency,
  setCurrency,
  getProductPriceWithTax,
  getCurrencySymbol,
  productPrice,
  setProductPrice,
  userEmail,
  successCallback,
  handleStatus,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const [isBusiness, setIsBusiness] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [validVatNumber, setValidVatNumber] = useState(false);
  const [disableCompanyNameInput, setDisableCompanyNameInput] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [productTax, setProductTax] = useState({
    country: '',
    zone: '',
    percentage: 0,
    currency: '',
    vat_number: null,
    company_name: null
  });
  const [previousVatNumberState, setPreviousVatNumberState] = useState<
    boolean | null
  >(null);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    country: '',
    city: '',
    line1: '',
    state: '',
    postal_code: '',
  });
  const [inputErrors, setInputErrors] = useState<PaymentInputErrors>({
    billingAddress: false,
    companyName: false,
    firstname: false,
    lastname: false,
    vatNumber: false
  });
  const [shouldValidateInputs, setShouldValidateInputs] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const getProductPrice = useCallback(
    (country?: string) => {
      if (!country) country = productTax.country;

      if (country) {
        setLoadingPrice(true);
        getProductPriceWithTax(country, {
          has_vat: vatNumber !== '',
          vat_number: vatNumber,
          company_name: companyName,
        })
          .then((response) => {
            if (response.data.message === 'success') {
              setProductTax(response.data.data.taxRate);
              setProductPrice(response.data.data.price);
              setCurrency(response.data.data.price.currency);
              if (response.data.data.vat.is_valid) {
                setValidVatNumber(true)
                if (response.data.data.vat.company_name) {
                  setCompanyName(response.data.data.vat.company_name)
                }
              } else {
                if (response.data.data.vat.vat_number != '') {
                  setCompanyName(response.data.data.vat.company_name)
                  setValidVatNumber(false)
                  setDisableCompanyNameInput(response.data.data.vat.company_name === 'INVALID VAT NUMBER')
                } else {
                  if (companyName == 'INVALID VAT NUMBER') {
                    setCompanyName("")
                  }
                  setValidVatNumber(true)
                }
              }
            } else {
              throw response.data.data.detail;
            }
          })
          .catch((error) => {
            toast('Error getting product price with tax');
          })
          .finally(() => {
            setLoadingPrice(false);
          });
      }
    },
    [
      vatNumber,
      companyName,
      productTax.country,
      getProductPriceWithTax,
      setProductTax,
      setProductPrice,
      setCurrency,
    ]
  );

  useEffect(() => {
    setDisableCompanyNameInput(false)
    setLoadingPrice(true)
    setValidVatNumber(false)

    const delayedAPICall = () => {
      getProductPrice();
    };

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = timeoutRef.current = window.setTimeout(delayedAPICall, 1000);

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [vatNumber]);

  useEffect(() => {
    if (shouldValidateInputs) {
      setInputErrors({
        firstname: firstname === '',
        lastname: lastname === '',
        companyName: companyName === '',
        vatNumber: vatNumber != "" && !validVatNumber,
        billingAddress:
            billingAddress.country === '' ||
            billingAddress.city === '' ||
            billingAddress.line1 === '' ||
            billingAddress.state === '' ||
            billingAddress.postal_code === ''
      })
    }
  }, [firstname, lastname, billingAddress, companyName, shouldValidateInputs]);

  const handleCountryChange = (value: string) => {
    setBillingAddress({
      ...billingAddress,
      country: value,
    });

    setValidVatNumber(true)
    // setVatNumber("")
    // setCompanyName("")
    if (product && value) getProductPrice(value);
  };
  const goToTermOfUse = () => {
    const url = '/terms-and-conditions';
    const tab = window.open(url, '_blank');
    tab?.focus();
  };

  const isFormValid = () => {
    if (firstname === '') {
      document.getElementById('input-firstname')?.focus()
      return false;
    }
    if (lastname === '') {
      document.getElementById('input-lastname')?.focus()
      return false;
    }
    if (billingAddress.country === '') {
      document.getElementById('input-country')?.focus()
      return false;
    }
    if (billingAddress.city === '') {
      document.getElementById('input-city')?.focus()
      return false;
    }
    if (billingAddress.line1 === '') {
      document.getElementById('input-line1')?.focus()
      return false;
    }
    if (billingAddress.state === '') {
      document.getElementById('input-state')?.focus()
      return false;
    }
    if (billingAddress.postal_code === '') {
      document.getElementById('input-postal_code')?.focus()
      return false;
    }
    if (companyName === '') {
      document.getElementById('input-companyName')?.focus()
      return false;
    }
    if (!validVatNumber) {
      document.getElementById('input-vatNumber')?.focus()
      return false;
    }

    return true;
  }

  const handleConfirm = async () => {
    setShouldValidateInputs(true);

    if (!isFormValid()) {
      return;
    }

    setShowButtonLoader(true);
    if (!stripe || !elements) {
      toast('An Error occurred');
      setShowButtonLoader(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: {
        name: firstname + ' ' + lastname,
        email: userEmail,
        address: billingAddress,
      },
      metadata: {
        has_vat: isBusiness && vatNumber !== '' ? 'true' : 'false',
        company_name: companyName,
        vat_number: vatNumber,
        currency: currency ? currency : 'usd',
      },
    });

    if (error) {
      cardElement?.focus()
      setShowButtonLoader(false);
      toast("Card validation failed");
      console.error('Erreur lors de la création du PaymentMethod :', error);
    } else {
      if (paymentMethod)
        successCallback &&
          successCallback(
            false,
            paymentMethod.id,
            setShowButtonLoader,
            savePaymentMethod,
            handleStatus
          );
      else {
        toast('An error occurred');
        setShowButtonLoader(false);
      }
    }
  };

  return (
    <div>
      <div className='forBillingCenter radius pt-3'>
        <div className='checkCard'>
          <b>Payment</b>
          <PaymentBrands />
        </div>
        <PaymentCard
          firstname={firstname}
          lastname={lastname}
          setFirstname={setFirstname}
          setLastname={setLastname}
          inputErrors={inputErrors}
        />
        <br />

        <PaymentBillingAddress
            billingAddress={billingAddress}
            handleCountryChange={handleCountryChange}
            inputErrors={inputErrors}
            setBillingAddress={setBillingAddress}
        />

        <div className='contentCheckboxsolution ml-3 mt-5'>
          <div className='custom-control custom-checkbox'>
            <input
              type='checkbox'
              className='custom-control-input'
              checked={true}
            />
            <label htmlFor='' className='custom-control-label'>
              I'm purchasing as a business
            </label>
          </div>
        </div>
        <div className='vatInformation required'>
          <label className='mr-3 ml-3 mt-3'>
            VAT information
            <div
                className={`container-error-address pl-2 ${
                    inputErrors.companyName ? 'd-inline' : 'd-none'
                }`}
            >
              <span className='error-red'> error </span>
              <span className='txt-error'>Company name field is required</span>
            </div>
            <div
                className={`container-error-address pl-2 ${
                    inputErrors.vatNumber ? 'd-inline' : 'd-none'
                }`}
            >
              <span className='error-red'> error </span>
              <span className='txt-error'>Invalid VAT number</span>
            </div>
          </label>
          <div className='name col-6 mt-1'>
            <input
              id='input-companyName'
              type='text'
              className='form-control'
              name='companyName'
              placeholder='Company name'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              disabled={disableCompanyNameInput || loadingPrice}
            />
          </div>
          <div className='name col-6 mt-2'>
            <input
              id='input-vatNumber'
              type='text'
              className='form-control'
              name='vat'
              placeholder='VAT number'
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              disabled={billingAddress.country == ""}
            />
          </div>
        </div>
        <div className='block pb-0 border-top-0 ml-3'>
          <div className='slidecheck'>
            <div className='custom-checkbox'>
              <input
                id='saveP'
                type='checkbox'
                className='custom-control-input'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSavePaymentMethod(event.target.checked);
                }}
                checked={savePaymentMethod}
              />
              <label htmlFor='saveP' className='custom-control-label test'><p>Save payment information</p></label>
                <p>Information is encrypted and securely stored</p>
            </div>
          </div>
        </div>
        <div className='col-6 border radius forBillingCenter contact float-right w-375 paiementBottom'>
          <div className='row forAds pl-3 pr-3 pb-0'>
            {loadingPrice && LoadingFeed()}
            {!loadingPrice && (
              <div className='col-12 align-items-baseline justify-content-between pt-4'>
                <div className='d-flex justify-content-between border-bottom'>
                  <h5>{product?.name}</h5>
                  <div>
                    <h5 className='color-orange text-align-right pr-0'>
                      {getCurrencySymbol()} {productPrice.amount.toFixed(2)}{' '}
                      {currency?.toUpperCase()}
                    </h5>
                  </div>
                </div>
                <br />
                <div className='d-flex justify-content-between pb-3'>
                  <h5>Subtotal</h5>
                  <div>
                    <h5 className='color-orange text-align-right pr-0'>
                      {getCurrencySymbol()} {productPrice.amount.toFixed(2)}{' '}
                      {currency?.toUpperCase()}
                    </h5>
                  </div>
                </div>
                <div className='d-flex justify-content-between border-bottom'>
                  <h5 className={'color-grey'}>
                    VAT ({productTax.percentage}%)
                  </h5>
                  <div>
                    <h5 className='color-grey text-align-right pr-0'>
                      {getCurrencySymbol()} {productPrice.tax.toFixed(2)}{' '}
                      {currency?.toUpperCase()}
                    </h5>
                  </div>
                </div>
                <br />
                <div className='d-flex justify-content-between'>
                  <h5>Amount due</h5>
                  <div>
                    <h5 className='text-align-right pr-0'>
                      {getCurrencySymbol()}{' '}
                      {productPrice.totalAmount.toFixed(2)}{' '}
                      {currency?.toUpperCase()}
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='forAds pl-3 pr-3'>
            <p className='mt-5'>
              {' '}
              By clicking "Confirm", you agree to our{' '}
              <a onClick={() => goToTermOfUse()} style={{ color: '#2a9df4' }}>
                terms & conditions
              </a>
              . You will be automatically charged for your purchase.
            </p>
          </div>
          <button
            className='btn btn-devinsider px-5 save ml-3 mr-3 mb-4'
            onClick={handleConfirm}
            disabled={ loadingPrice || showButtonLoader }
          >
            <BtnNext text={'Confirm'} showLoader={showButtonLoader} />
          </button>
        </div>
      </div>
    </div>
  );
};
