/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { url } from "../../../service/constant";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { postJsonDataService } from "../../../service/applicatif/company-page-investor";
import { Link, useHistory } from "react-router-dom";
import {
  concatString,
  dateDiff,
  getTxtNotif,
  limitedText,
} from "../../../utils";
import { addNotification } from "../../../redux/actions/notification-action";
import { Notification } from "../../../redux/types/notification-interface";
import { useKeycloak } from "@react-keycloak/web";
type IProps = {};

export default function BlockNotif(props: IProps) {
  //Dev #48735,Bug pour indiquer que la notif est "lus"
  const state = useSelector((state: rootState) => state);
  const userReducer = state.userReducer;
  const { keycloak } = useKeycloak();

  const notificationReducer = state.notificationReducer;
  const [notification, setnotification] = useState<Array<Notification>>([]);
  const [loaderRead, setLoaderRead] = useState<boolean>(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const showUserActivity = (userId: number) => {
    history.push("/community/activity-user/" + userId);
  };

  const markAllNotisAsRead = async () => {
    const result = await postJsonDataService(
      url.notification.mark_all_as_read,
      {},
      keycloak?.token as string
    );
    if (result?.status === 200) {
      const tempNotif: Array<Notification> = notification.map(
        (el: Notification) => ({ ...el, status: true })
      );
      dispatch(addNotification(0, tempNotif));
      setnotification(tempNotif);
    }
  };

  const showCommunityPost = (notif: Notification) => {
    const categorieId = notif?.post?.category?.id;
    const postId = notif?.post?.id;
    history.push("/community/reply-post/" + categorieId + "/" + postId);
  };

  useEffect(() => {
    async function getNotification() {
      const response = await postJsonDataService(
        url.notification.get_notification,
        {
          type: "",
          page: 1,
        },
        keycloak?.token as string
      );
      if (response?.status === 200) {
        setnotification(response?.data?.list_notifications);
        dispatch(
          addNotification(
            response?.data?.number_notifcations_unread,
            response?.data?.list_notifications
          )
        );
      }
    }
    if (keycloak?.token) {
      getNotification();
    }
    return () => {};
  }, []);

  const showAllNotif = () => {
    history.push("/notifications");
  };

  const markNotifAsRead = async (notif: Notification, haveRedirectLink = false) => {
    const isNotRead = userReducer?.token && !notif.status;

    if (isNotRead) {
      setLoaderRead(true);
    await readNotif(notif);
    } 
    // Redirect to the page after the notification has been marked as read (or if it already is)
   if (!haveRedirectLink) redirectToNotification(notif);
  };

  const readNotif = async (notif: Notification) => {
    try {
      const response = await postJsonDataService(
        url.notification.mark_read + notif.id,
        {},
        keycloak?.token as string
      );

      if (response?.status === 200) {
        const tempNotif = notificationReducer.data.map((el: Notification) => {
          if (el.id === notif.id) {
            return {
              ...el,
              status: true,
            };
          } else {
            return el;
          }
        });

        setnotification(tempNotif);

        dispatch(
          addNotification(notificationReducer.totalNumberUnread - 1, tempNotif)
        );
      }
    } catch (error) {
      console.error("Error in readNotif:", error);
    } finally {
      setLoaderRead(false);
    }
  };

  const redirectToNotification = (notif: Notification) => {
    const categorieId = notif?.post?.category?.id;
    const postId = notif?.post?.id;

    if (categorieId && postId) {
      history.push("/community/reply-post/" + categorieId + "/" + postId);
    }
  };

  return (
    <div className="item notif">
      <Link to="#" className="linkHeader"  
          onClick={(e) => {
            e.preventDefault();
            showAllNotif();
          }}>
        <span className="icon-notification"></span>
        <span className="mobile">Notification</span>
        {/* Dev #48735,Bug pour indiquer que la notif est "lus" */}
        {notificationReducer.totalNumberUnread > 0 ? (
          <span className="non-lu">
            {notificationReducer.totalNumberUnread}
          </span>
        ) : (
          <></>
        )}
      </Link>

      <div className="submenu">
        {notification.length > 0 ? (
          <>
            <div className="head_notif">
              <Link to="#" className="text_notif">
                Notification{" "}
                {notificationReducer.data.length > 0 &&
                  "(" + notificationReducer.data.length + ")"}
              </Link>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  !loaderRead && markAllNotisAsRead();
                }}
              >
                Mark all as read
              </Link>
            </div>
            {notificationReducer.data?.map((notif: Notification, index) => {
              return (
                <Link
                  to="#"
                  className="all_notif"
                  onClick={(e) => {
                    e.preventDefault();
                    markNotifAsRead(notif);
                  }}
                  key={index}
                >
                  <div className={!notif?.status ? "unread" : ""}>
                    <span className="icon-bubble mr-2 ico"></span>
                    <span className="message type">
                      {notif?.type != "SOLUTION_DISCUSSION_I_PARTICIPATED" &&
                      notif?.type !=
                        "SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE" ? (
                        <span
                          className="user"
                          onClick={() =>
                            showUserActivity(notif?.user_who_notify?.id)
                          }
                        >
                          {concatString(
                            notif?.user_who_notify?.first_name,
                            notif?.user_who_notify?.last_name,
                            " "
                          )}
                        </span>
                      ) : (
                        <span className="defaultComment">
                          The forum discussion{" "}
                        </span>
                      )}
                      {notif?.type != "SOLUTION_DISCUSSION_I_PARTICIPATED" &&
                      notif?.type !=
                        "SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE" ? (
                        ""
                      ) : (
                        <span
                          className="yourComment"
                          onClick={async (e) => {
                              e.preventDefault();
                             await markNotifAsRead(notif, true);
                              showCommunityPost(notif)
                             }
                          }
                        >
                          {limitedText(notif?.post?.title, 20) + " "}
                        </span>
                      )}
                      <span className="defaultComment">
                        {" " + getTxtNotif(notif?.type) + " "}
                      </span>{" "}
                      <span
                        className="yourComment"
                        onClick={async (e) => {
                          e.preventDefault();
                         await markNotifAsRead(notif, true);
                          notif?.type != "SOLUTION_DISCUSSION_I_PARTICIPATED" &&
                          notif?.type !=
                            "SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE"
                            ? showCommunityPost(notif)
                            : showUserActivity(notif?.user_who_notify?.id)
                          }
                        }
                      >
                        {notif?.type != "SOLUTION_DISCUSSION_I_PARTICIPATED" &&
                        notif?.type !=
                          "SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE"
                          ? limitedText(notif?.post?.title, 20)
                          : notif?.user_who_notify?.first_name}
                      </span>
                    </span>
                    <span className="date">{dateDiff(notif?.created_at)}</span>
                  </div>
                </Link>
              );
            })}{" "}
            <Link
              to="#"
              className="seeAll"
              onClick={(e) => {
                e.preventDefault();
                showAllNotif();
              }}
            >
              See all
            </Link>
          </>
        ) : (
          <>
            <div className="noNotif">
              <div className="head_notif">
                <span className="text_notif">Notification</span>
              </div>
              <p className="txt">No new notifications</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
