import React from "react";
import { useHistory, useLocation } from "react-router";
import { FundingStage } from "../../../interface/compnay-page-investor";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { checkIsCompletedInvestorCompanyMatching } from "../../../utils";
const queryString = require("query-string");
enum urlType {
  findInvestor,
  unlocked,
  favorite,
}

export default function SecondHeaderInvestor() {
  const history = useHistory();

  const location = useLocation();
  let queryParameter = queryString.parse(location?.search);
  const selectedStage = queryParameter ? queryParameter.stage : "all";

  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const companyPageReducer = useSelector(
    (state: rootState) => state.userReducer?.isvInCompanyPage?.company_page
  );

  const goToFindInvestor = (stage: FundingStage = "all") => {
    if (!checkIsCompletedInvestorCompanyMatching(companyPageInfo)) {
      history.push({
        pathname: "/find-investors",
        search: "?stage=" + selectedStage || "all",
      });
    } else {
      history.push({
        pathname: "/investor/find-investor-wrapper",
        search: "?stage=" + selectedStage || "all",
      });
    }
  };
  const goToInvestorUnlocked = () => {
    history.push({
      pathname: "/investor/investor-unlocked",
      search: "?stage=" + selectedStage ||  "all",
    });
  };

  const goToFavoriteInvestor = () => {
    history.push({
      pathname: "/investor/favorite",
      search: "?stage=" + selectedStage || "all",
    });
  };
  const url = window.location.href;
  let submenu = url?.includes("find-investor")
    ? urlType.findInvestor
    : url?.includes("favorite")
    ? urlType.favorite
    : url?.includes("investor-unlocked")
    ? urlType.unlocked
    : urlType.findInvestor;
  return (
    <>
      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">Find investors</h2>
            <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    goToFindInvestor();
                  }}
                  className={submenu === urlType.findInvestor ? "active" : ""}
                >
                  Matching results
                </a>
              </div>
              {companyPageReducer && (
                <div className="item">
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToInvestorUnlocked();
                    }}
                    className={submenu === urlType.unlocked ? "active" : ""}
                  >
                    Investors unlocked
                  </a>
                </div>
              )}
              {companyPageReducer && (
                <div className="item">
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToFavoriteInvestor();
                    }}
                    className={submenu === urlType.favorite ? "active" : ""}
                  >
                    Favorites
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="rightItemHeader" />
        </div>
      </div>
    </>
  );
}
