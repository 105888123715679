import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { rootState } from "../../redux/reducers";
import { deleteAlternativeFinancing } from "../../redux/actions/alternative-financing-action";
import { OEM_PROGRAM, url } from "../../service/constant";
import { conversationType } from "../../utils";
import { toast } from "react-toastify";
import { getDataService, postJsonDataService } from "../../service/applicatif/back-end-service";
import store from "../../redux/store";

export default function AlternativeController() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: { id?: string | undefined } = useParams();

    const [showLoading, setShowLoading] = useState(false);
    const userReducer = useSelector((state: rootState) => state.userReducer);

    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);

    const [showLogin, setShowLogin] = useState<boolean>(false);

    // const alternativeReducer = useSelector(
    //     (state: rootState) => state?.alternativeReducer?.alternative
    // );

    const [alternativeReducer, setAlternativeReducer] = useState<any | null>();

    const oemProgramReducer = useSelector(
        (state: rootState) => state?.oemProgramReducer.program
    );

    const isMyPost = alternativeReducer?.user?.id === userReducer?.id;
    const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;

    const callback = (data: any) => {
        setShowLoading(false);
    };

  const getDetailAlternative = async (params: any) => {
    try {
        let response = await getDataService(
          url?.alternative?.getAlternative+"/"+params,
          {},
          store && store?.getState()?.userReducer?.token
        );
        if(response?.status === 200) {
          setAlternativeReducer(response.data);
        }
        setShowLoading(false);
      } catch (error: any) {}
    setShowLoading(false);
  };
  

    const getDetail = () => {
      setShowLoading(true);
      getDetailAlternative(params.id || 0);
    }
    
    useEffect(() => {
        getDetail();
    }, []);

    const handleSeeSolution = (action: string) => {
        const dataS = [
          ...(alternativeReducer?.software_solution || []),
          ...(alternativeReducer?.featured_solution || []),
        ];
        setLimitSolution(action === '0' ? dataS.length || 0 : 6);
    };
    
    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(
            action === '0' ? alternativeReducer?.targeted_industry?.length || 0 : 6
        );
    };

    // const cb = (data: any) => {
    //   if(data !== null) {
    //     dispatch(getDetailAlternative(params.id || 0, callback));
    //   }
    // };

    const handleAddFavorite = async () => {
        if(userReducer?.token === '') {
            return setShowLogin(true);
        } else {
            let parames = {
                alternative_financing: params.id || 0,
                oem_program: isOem ? oemProgramReducer.id : '',
            }
            try {
              let response = await postJsonDataService(
                  url?.alternative?.addFavorite,
                  parames,
                  store && store?.getState()?.userReducer?.token
              );
              if(response?.status === 200) {
                const tempFavorite: any = {
                  ...alternativeReducer,
                  liked: !alternativeReducer?.liked,
                };
                setAlternativeReducer(tempFavorite);
              }
            } catch (error) {
              const tempFavorite: any = {
                ...alternativeReducer,
                liked: !alternativeReducer?.liked,
              };
              setAlternativeReducer(tempFavorite);
            }
        }
    }

    const showDiscussion = () => {
        history.push('/contact/discussion');
    };

    const isDisabled = (haveP: boolean | undefined): boolean => {
        let isDisabled = true;
        if (isMyPost) {
          isDisabled = haveP ? false : true;
        } else {
          isDisabled = false;
        }
        return isDisabled;
    };
    
    const sendMessage = (haveP: boolean | undefined) => {
      if (isDisabled(haveP)) {
        return;
      }
      if (userReducer?.token === '') {
        return setShowLogin(true);
      }
      if (isMyPost) {
        showDiscussion();
        return;
      }
      history.push('/contact/discussion', {
        userId: alternativeReducer?.id,
        discussionType: conversationType.alternative,
      });
    };

    const editPost = () => {
      history.push('/alternative-financing/edit/' + parseInt(params.id || '0'));
    };

    const cbDelete = (data: any) => {
      if(data) {
        toast("Delete successfully");
        history.push("/alternative-financing/listing");
      } else {
        toast("An error occured");
      }
    }

    const deletePost = () => {
      deleteAlternativeFinancing(params.id || 0, cbDelete);
    }

    return {
        alternativeReducer,
        showLoading,
        limitIndustry,
        limitSolution,
        handleSeeIndustry,
        handleSeeSolution,
        isMyPost,
        showLogin,
        setShowLogin,
        handleAddFavorite,
        userReducer,
        isDisabled,
        sendMessage,
        editPost,
        deletePost,
    }
}