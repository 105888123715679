/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from 'react';
import { Redirect } from 'react-router-dom';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import './activate-email.css';
import { ReactComponent as Loader } from '../../../../../resources/images/loader-chargement.svg';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import useActivateEmail from '../../../../../controller/account-ctr/complete-create-acount-isv/use-activate-email';
import { useKeycloak } from '@react-keycloak/web';

export default function ActivateEmail() {
  const { id } = useActivateEmail();
  const {keycloak} = useKeycloak()
  if (id == null && id === undefined) {
    return keycloak.login()
  }
  return (
    <div>
      <Header />
      <div className='activate-loader'>
        <Loader />
      </div>
    </div>
  );
}
