import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { postWithoutTokenCombineUrl } from "../../../service/api";
import { url } from "../../../service/constant/index";
import { useKeycloak } from "@react-keycloak/web";
const queryString = require("query-string");

export default function useActivateEmail() {
  let params: any = useLocation();
  const [tokenId, setTokenId] = useState(queryString.parse(params?.search));
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const {keycloak} = useKeycloak()
  useEffect(() => {
    const resendEmail = async () => {
      setShowLoader(true);
      setError(false);

      let response = await postWithoutTokenCombineUrl(
        url.acount.activateUserAcount,
        tokenId.id
      );
      if (response?.status === 200) {
        keycloak.login({redirectUri:"/"})
      }
    };

    resendEmail();
  }, []);
  return {
    id: tokenId.id,
  };
}
