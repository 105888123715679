import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { rootState } from "../../redux/reducers";
import { OemProgram, OemProgramDraft } from "./type";
import {
  clearOemCahe,
  getOemAttributBenefic,
  getOemAttributGetStarted,
  getOemAttributOverview,
  getOemAttributSolution,
  getOemAttributte,
  getOemProfileProgram,
  publishOrUpdateAction,
  unpublishOemProgram,
} from "../../redux/actions/oem-program-action";
import Alert from "../../screen/widget/alert-message/info";
import {
  updateOemprogramName,
  updateOemRoleListe,
} from "../../redux/actions/acounts-action";
import {
  patchDataService,
  postJsonDataService,
} from "../../service/applicatif/back-end-service";
import { OEM_PROGRAM, url } from "../../service/constant";
import UsePersmission from "../../screen/widget/permission-oem/use-persmission";
import { conversationType, isExist, oem_roles } from "../../utils";
import { toast } from "react-toastify";

const queryString = require("query-string");

export default function UseProfilPageEdit() {
  const params: any = useParams();

  const [editMode, setEditMode] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const dispatch = useDispatch();
  const { handLePersmission, showPermission } = UsePersmission();
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const oemProgramDraft: OemProgramDraft = useSelector(
    (state: rootState) => state.oemProgramReducer.draft
  );

  const [oemProgramState, setOemProgramState] = useState<any>();

  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [loadingPage, setloadingPage] = useState<boolean>(false);

  const programId = params.id;

  const userType = userReducer.roles?.filter((el) => el !== "ROLE_USER");
  const isNotOemAccount = userType?.[0] !== OEM_PROGRAM;

  const [isDraftExist, setisDraftExist] = useState<boolean>(false);
  const [loader, setloader] = useState<boolean>(false);
  const location: any = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const [isCompletedProgram, setIsCompletedProgram] = useState(true);
  const [showPopupUnpublish, setShowPopupUnpublish] = useState<boolean>(false);
  const [showBtnUnpublish, setShowBtnUnpublish] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [gearClick, setGearClick] = useState(1);

  const [configOtherCompany, setConfigOtherCompany] = useState<{
    is_favorite: boolean;
    matching: number | string;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
  }>({
    is_favorite: false,
    matching: "",
    user: {
      first_name: "",
      id: 0,
      last_name: "",
    },
  });

  const isOtherCompanyPage = () => {
    let isMemberCompany: any;
    if (isNotOemAccount) {
      isMemberCompany = oemProgram?.oemProgramMembers?.find(
        (el: any) => el?.user.id?.toString() === userReducer?.id?.toString()
      );
      return isMemberCompany ? false : true;
    } else {
      return false;
    }
  };

  const cbAttribute = (response: any) => {
    if (!response) {
      history.push("/profil-not-found");
    } else {
      const keys = Object.keys(response)[0];
      const values = Object.values(response)[0];

      setOemProgramState({
        ...oemProgramState,
        [keys]: values,
      });
    }
  };

  const cb = (response: any) => {
    if (response?.data) {
      setloadingPage(false);
      setOemProgramState({
        ...oemProgramState,
        ...response.data,
      });
    } else {
      history.push("/profil-not-found");
    }
  };

  const initialiseAttribute = () => {
    if (programId) {
      dispatch(
        getOemAttributSolution(programId + "/oemProgramSolutions", cbAttribute)
      );

      dispatch(
        getOemAttributGetStarted(programId + "/oemProgramStarteds", cbAttribute)
      );

      dispatch(
        getOemAttributOverview(programId + "/oemProgramOverviews", cbAttribute)
      );

      dispatch(
        getOemAttributBenefic(programId + "/oemProgramBenefits", cbAttribute)
      );

      dispatch(
        getOemAttributte(
          "segment_details",
          programId + "/segmentDetails",
          cbAttribute
        )
      );
      dispatch(
        getOemAttributte("segments", programId + "/segments", cbAttribute)
      );

      dispatch(
        getOemAttributte(
          "targeted_industry",
          programId + "/targetedIndustry",
          cbAttribute
        )
      );

      dispatch(
        getOemAttributte(
          "targeted_country",
          programId + "/targetedCountry",
          cbAttribute
        )
      );
    }
  };

  useEffect(() => {
    if (programId) {
      setloadingPage(true);
      dispatch(getOemProfileProgram(programId, cb));
      initialiseAttribute();
    }
  }, [programId]);

  useEffect(() => {
    setDefaultValue({
      programName: oemProgramDraft?.programName
        ? oemProgramDraft?.programName
        : oemProgram?.program_name,
    });
  }, [oemProgramDraft, oemProgram]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const switchEditMode = () => {
    setEditMode(!editMode);
  };

  //Return true if a data draft is emptyValue and false otherWay
  const isEmptyWysiwig = (data: any): boolean => {
    let draftWysiwyg = data?.filter((el: any) => el?.draft === true);
    const isEmpty = (currentValue: any) =>
      JSON.parse(currentValue?.html)?.blocks?.every(
        (item: any) => item?.text === ""
      );
    return draftWysiwyg?.every(isEmpty);
  };

  const proGramIsCompleted = (): boolean => {
    const isNotProfil =
      oemProgram.photo_profil.includes("profil") && !oemProgram.exist_draft;

    return (
      !isEmptyWysiwig(oemProgram?.oemProgramOverviews) &&
      !isEmptyWysiwig(oemProgram?.oemProgramSolutions) &&
      !isEmptyWysiwig(oemProgram?.oemProgramBenefit) &&
      !isEmptyWysiwig(oemProgram?.oemProgramStarted) &&
      !isNotProfil
    );
  };

  const cbpublish = (data: any) => {
    setloader(false);
   
    dispatch(
      updateOemprogramName({
        oem_name: oemProgram?.program_name,
        id: oemProgram?.id,
      })
    );

    dispatch(updateOemRoleListe());

    initialiseAttribute();
    
    if (!oemProgram?.status && !oemProgram?.validate_by_devinsider) {
      Alert({
        txt: [
          "Thank you for publishing your page. Before your page will be visible to ISVs, Devinsider will review and approve the page.",
          "A Devinsider representative will notify you by email once the page has been approved.",
        ],
      });
    } else {
      toast("Your program has been updated successfully!");
    }
  };

  const publishOrUpdate = () => {
    let isNotCompletedWysiwig = !proGramIsCompleted();

    const isExistDraft = oemProgram.exist_draft;

    const isNotProfil =
      isExistDraft &&
      oemProgram.photo_profil?.includes("profil.png") &&
      !isExist(oemProgram.photo_profil_draft);

    if (isNotCompletedWysiwig || isNotProfil) {
      Alert({
        txt: [
          "To provide ISVs with information about your program, you need to",
          " complete the sections overview, solutions, logo, key benefits and getting",
          " started before you can publish your program page.",
        ],
      });
    } else {
      setloader(true);
      dispatch(publishOrUpdateAction(params?.id, cbpublish));
    }
  };

  const showPreview = () => {
    window.open("/OEM/profil/" + oemProgram.id + "?preview=1", "_blank");
  };

  const goBackMatchingPanel = () => {
    history.push("/programs/program-matching");
  };

  const addFavorite = async () => {
    let result: any = await patchDataService(
      url?.programs?.mark_as_favorite + programId,
      {},
      userReducer?.token
    );
    if (result?.status === 200) {
      setConfigOtherCompany({
        ...configOtherCompany,
        is_favorite: !configOtherCompany.is_favorite,
      });
    }
  };
  useEffect(() => {
    setIsCompletedProgram(proGramIsCompleted());
    return () => {};
  }, [oemProgram]);

  const validateEventRole = () => {
    const currentOemId = oemProgram?.id;
    const userRole = userReducer?.oemProgramMembers?.find(
      (el) => el.oem_program.id?.toString() === currentOemId.toString()
    );
    let usertype = userRole?.role === oem_roles?.editor;
    if (usertype) {
      handLePersmission();
      return;
    } else {
      return true;
    }
  };

  const validateEventEditProgram = () => {
    const currentOemId = oemProgram?.id;
    const userRole = userReducer?.oemProgramMembers?.find(
      (el) => el.oem_program.id?.toString() === currentOemId.toString()
    );

    let usertype = userRole?.role === oem_roles?.outreach_representative;

    if (usertype) {
      handLePersmission();
      return;
    } else {
      return true;
    }
  };

  const validatePublishEvent = () => {
    const currentOemId = oemProgram?.id;
    const userRole = userReducer?.oemProgramMembers?.find(
      (el) => el.oem_program.id?.toString() === currentOemId.toString()
    );
    let usertype = userRole?.role === oem_roles?.outreach_representative;
    if (usertype) {
      handLePersmission();
      return;
    } else {
      return true;
    }
  };

  const contactOemPage = () => {
    if (isOtherCompanyPage()) {
      history.push("/contact/discussion", {
        userId: programId,
        discussionType: conversationType.isv_to_oem,
      });
    }
  };

  useEffect(() => {
    async function getInfoConfigCompany() {
      const dataToSend = {
        operation: "ISV_FIND_OEM",
        source: companyPageReducer.id,
        target: params.id,
      };
      const response = await postJsonDataService(
        url.company.get_other_company_config,
        dataToSend,
        userReducer.token
      );
      if (response?.status === 200) {
        setConfigOtherCompany(response?.data?.details);
      }
    }
    if (params.id && companyPageReducer.id !== "0") {
      getInfoConfigCompany();
    }
  }, [params.id]);

  const handleUnpublish = async () => {
    setProcessing(true);
    dispatch(
      unpublishOemProgram(oemProgram?.id, (status) => {
        if (status) {
          toast("Your program has been unpublished successfully!");
          return;
        }
        toast("An error occurred, please try again!");
        return;
      })
    );
    setShowBtnUnpublish(false);
    setShowPopupUnpublish(false);
    setProcessing(false);
    setGearClick(gearClick + 1);
  };

  useEffect(() => {
    return () => {
      if (isNotOemAccount) {
        dispatch(clearOemCahe());
      }
    };
  }, []);

  return {
    editMode,
    setEditMode,
    oemProgram,
    scrollPosition,
    switchEditMode,
    defaultValue,
    isDraftExist,
    loader,
    publishOrUpdate,
    showPreview,
    isCompletedProgram,
    isOtherCompanyPage: isOtherCompanyPage(),
    goBackMatchingPanel,
    addFavorite,
    handLePersmission,
    showPermission,
    validateEventRole,
    validateEventEditProgram,
    validatePublishEvent,
    loadingPage,
    contactOemPage,
    configOtherCompany,
    isPreview: parsed?.preview,
    programId,
    isNotOemAccount,
    handleUnpublish,
    showPopupUnpublish,
    showBtnUnpublish,
    setShowPopupUnpublish,
    setShowBtnUnpublish,
    processing,
    gearClick,
    setGearClick,
    oemProgramState,
  };
}
