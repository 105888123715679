/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { IpictureSelect } from "./type";
import Button from "react-bootstrap/Button";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import { fileUrl } from "../../../utils";

export default function ModalPhoto(props: IpictureSelect) {
  let txtLogo = "";
  if (props.isProfil) {
    txtLogo = "photo";
  } else {
    if (props.largeDesign) {
      txtLogo = "image";
    } else {
      txtLogo = "logo";
    }
  }
  return (
    <>
      <Modal
        show={props.isShowImage}
        onHide={() => {
          props.hideSelectedFile();
        }}
        className={`${props.largeDesign ? "largePhoto" : ""} modalDevinsider`}
      >
        <Modal.Header closeButton>
          {props.isProfil ? (
            <Modal.Title>Edit photo</Modal.Title>
          ) : (
            <Modal.Title>
              Edit {props?.largeDesign ? "background" : "logo"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div
              className={`imageProfil mx-auto ${
                props.fileUrl ? "overnoBg" : ""
              }`}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  props.setShowImage(true);
                }}
              >
                <img
                  src={
                    props.selectedFile
                      ? URL.createObjectURL(props.selectedFile)
                      : `${fileUrl(props.fileUrl)} `
                  }
                  alt=""
                />
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="sameBtn">
          <Button className="validate " onClick={() => props.handleBtn()}>
            Select {txtLogo}
            <input
              type="file"
              id="getFile"
              accept="image/png, image/jpeg"
              ref={props.fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                props.changeHandler(event)
              }
              style={{ display: "none" }}
            />
          </Button>
          <>
            <Button
              className="validate"
              onClick={() => props.updateUserDetail("photo")}
              disabled={props.isLoaderShow || !props.selectedFile}
            >
              {props.isLoaderShow ? <Loader /> : "Save"}
            </Button>
            <Button
             disabled={props.isLoaderShow || !props.selectedFile}
              className="cancelbtn"
              onClick={() => props?.restorePhotoChange()}
            >
              {props.processingRemovePhotoProfile ? (
                <Loader />
              ) : (
                "Delete " + txtLogo
              )}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}
