import Select from "react-select";
import Header from "../../../widget/index";
import "./FindInvestor.scss";
import FindInvestorBlocFilter from "./bloc-filter-find-investor/bloc-filter-find-investor";
//@ts-ignore
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UseFindInvestor from "../../../../controller/investor-ctr/find-investor/use-find-investor";
import MatchingInvestor from "../../../../hooks/MatchingInvestor";
import { FundingStage } from "../../../../interface/compnay-page-investor";
import { IInvestor } from "../../../../redux/types/find-investor-interface";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import { fileUrl } from "../../../../utils";
import { LoaderCommon } from "../../../widget/loader";
import ModalPermission from "../../../widget/permission-oem/index";
import Pagination from "../../../widget/use-pagination/pagination";
import { PaymentPopup } from "../../payment-popup/payment-popup";

export type Props = {
  stage?: FundingStage;
};
export const FindInvestor: React.FC<Props> = ({ stage }) => {
  const history = useHistory();
  const providerFindInvestor = UseFindInvestor(stage || "all");
  const providerModalUnlock =
    providerFindInvestor?.providerModalUseUnlockInvestor;

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const goToInvestorPage = (id: any) => {
    history.push("/inv-page/" + id + "?preview=1&stage=" + stage || "all");
  };

  const paymentMethodCallBack = (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    if (providerModalUnlock?.investorToUnlockId)
      providerFindInvestor.unlockedInvestor(
        isFree,
        paymentMethodId,
        providerModalUnlock?.investorToUnlockId,
        setLoading,
        providerModalUnlock?.setShowPaymentModal,
        savePaymentMethod,
        setShowPaymentStatus
      );
    else {
      toast("An error occurred");
      setLoading(false);
      providerModalUnlock?.setShowPaymentModal(false);
    }
  };

  const handleStatusOk = () => {
    history.push("/inv-page/" + providerModalUnlock?.investorToUnlockId);
  };

  const renderPercent = (percentage: number, is_unlocked: boolean) => {
    if (percentage < 60) {
      return (
        <div className={`matchValue m60 ${!is_unlocked ? " locked" : ""}`}>
          {"<60"}% Match
        </div>
      );
    } else if (percentage >= 60 && percentage < 80) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? " locked" : ""}`}>
          {percentage}% Match
        </div>
      );
    } else if (percentage >= 80 && percentage < 90) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? " locked" : ""}`}>
          {percentage}% Match
        </div>
      );
    } else if (percentage >= 90) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? " locked" : ""}`}>
          {percentage}% Match
        </div>
      );
    } else {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? " locked" : ""}`}>
          {percentage}% Match
        </div>
      );
    }
  };

  const renderButton = (el: IInvestor) => {
    if (el?.is_unlocked) {
      return (
        <div className="containerAction">
          <div className="lockedPage">
            Unlocked on {moment(el?.unlocked_at).format("MMMM D, yyyy")}
          </div>
          <div className="btn-group" role="group">
            <button
              className="cancel btn btn-devinsider"
              onClick={() => goToInvestorPage(el?.investor?.id)}
            >
              View
            </button>
            <button
              className="cancel btn btn-devinsider"
              onClick={() => {
                providerFindInvestor?.contactIvestor(el?.investor?.id);
              }}
            >
              Contact
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="containerAction">
          <div className="lockedPage">
            <span className="icon-locked"></span>Page locked
          </div>
          <div className="btn-group" role="group">
            {el?.matching_value < 60 ? (
              <>
                <button
                  className="cancel btn btn-devinsider"
                  onClick={() => goToInvestorPage(el?.investor?.id)}
                >
                  Preview
                </button>
                <button className="cancel btn btn-devinsider disabled" disabled>
                  Unlock
                </button>
              </>
            ) : (
              <>
                <button
                  className="cancel btn btn-devinsider"
                  onClick={() => goToInvestorPage(el?.investor?.id)}
                >
                  Preview
                </button>
                <button
                  className="cancel btn btn-devinsider"
                  onClick={() => {
                    providerModalUnlock?.goToPaymentModal(
                      String(el?.investor?.id)
                    );
                  }}
                >
                  {String(el?.investor?.id) ===
                    providerModalUnlock?.investorToUnlockId &&
                    providerModalUnlock?.isGettingFreePlan && (
                      <div className="loadIcon">
                        <Loader />
                      </div>
                    )}
                  {(String(el?.investor?.id) !==
                    providerModalUnlock?.investorToUnlockId ||
                    !providerModalUnlock?.isGettingFreePlan) && <>Unlock</>}
                </button>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const isIndividual = (el: IInvestor): boolean => {
    return !el?.investor?.institutional_investor_type
      ? true
      : el?.investor?.institutional_investor_type?.name === "Business Angel"
      ? true
      : false;
  };

  const renderFindMatchmakingPanel = () => {
    return (
      <>
        <Header />
        <FindInvestorBlocFilter {...providerFindInvestor} />

        <PaymentPopup
          show={providerModalUnlock?.showPaymentModal}
          title={"Unlock Investor"}
          setShowPaymentModal={providerModalUnlock?.setShowPaymentModal}
          product={providerModalUnlock?.product}
          successCallback={paymentMethodCallBack}
          free={providerModalUnlock?.freeUnlock}
          isPriceInfo={false}
          handleStatusOk={handleStatusOk}
        />
        <div className="container mx-auto minHeightwin">
          <div className="listOfIsv">
            {!providerFindInvestor?.loader &&
              providerFindInvestor?.investors?.state !== "IN_PROGRESS" && (
                <div className="headerOfList">
                  <div className="leftHead">
                    <div className="number">
                      Showing{" "}
                      <b>
                        {providerFindInvestor?.investors?.total_to_paginate}
                      </b>{" "}
                      Investors
                    </div>
                    <form
                      action=""
                      onClick={(e) => openBlock(e)}
                      onSubmit={providerFindInvestor?.handleSubmit(
                        providerFindInvestor?.onSubmitSearch
                      )}
                    >
                      <input
                        placeholder="Search..."
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        {...providerFindInvestor?.register("search")}
                      />
                    </form>
                  </div>
                  <div className="rightHead">
                    <div className="sortedby labelSelect">
                      <label>Sorted by :</label>
                      <Select
                        className="triOreder"
                        isClearable={false}
                        options={providerFindInvestor?.filtered}
                        classNamePrefix="tri"
                        defaultValue={providerFindInvestor?.filtered[0]}
                        value={
                          providerFindInvestor?.filtered?.filter(
                            (e) => e?.value === providerFindInvestor?.sortBy
                          )[0]
                        }
                        onChange={(filter: any) =>
                          providerFindInvestor?.handleFilter(filter)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            <div className="scrollContainer">
              {providerFindInvestor?.loader ||
              providerFindInvestor?.investors?.state === "IN_PROGRESS" ? (
                <LoaderCommon
                  progress={providerFindInvestor?.investors?.progress}
                />
              ) : (
                <ul className="listOfIsvMatch">
                  {providerFindInvestor?.investors?.results?.map((el, key) => (
                    <li key={key}>
                      <div className="head">
                        <div
                          className={`isvLogo ${
                            isIndividual(el) ? "b-radius" : ""
                          } ${el?.is_unlocked ? "" : "locked"}`}
                        >
                          <img
                            src={fileUrl(el?.investor?.photo_profil)}
                            alt=""
                          />
                        </div>
                        <div className="isvInfo">
                          <h3 className="title">
                            <span className="name">
                              {el?.investor?.organization_name}
                            </span>
                            <span
                              className={
                                el?.is_favorite
                                  ? "icon-star-on"
                                  : "icon-star-off"
                              }
                              onClick={() =>
                                providerFindInvestor?.addToFavorites(
                                  el?.investor?.id
                                )
                              }
                            ></span>
                          </h3>
                          {renderPercent(el?.matching_value, el.is_unlocked)}
                        </div>
                      </div>
                      <div className="foot">
                        <div>
                          <p className="title">INVESTOR TYPE</p>
                          <span className="semi-bold">
                            {el?.investor?.institutional_investor_type
                              ? el?.investor?.institutional_investor_type?.name
                              : el?.investor?.investor_type?.name}
                          </span>
                        </div>
                        <div>
                          <p className="title">Headquarters location</p>
                          <span className="semi-bold">
                            <ReactCountryFlag
                              countryCode={el?.investor?.country?.cc_iso}
                              svg
                              title={el?.investor?.country?.code}
                            />{" "}
                            {el?.investor?.country?.code}
                          </span>
                        </div>
                      </div>
                      {renderButton(el)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {!providerFindInvestor?.loader &&
              providerFindInvestor?.investors?.state !== "IN_PROGRESS" && (
                <div className="pagginationCategory fixePagination">
                  <div className="pagination">
                    <Pagination
                      className="pagination"
                      currentPage={providerFindInvestor?.page || 1}
                      totalCount={
                        providerFindInvestor?.investors?.total_to_paginate
                      }
                      pageSize={providerFindInvestor?.limit}
                      onPageChange={(page: any) =>
                        providerFindInvestor?.loadMoreProgram(page)
                      }
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  };

  if (!providerFindInvestor.isIsv) {
    return <Redirect to={"/profil-not-found"} />;
  }

  return (
    <>
      <MatchingInvestor>
        <div className="findIsvcontainer findInvestor">
          {renderFindMatchmakingPanel()}
        </div>
      </MatchingInvestor>
      <ModalPermission
        show={providerFindInvestor.showPermission}
        handleSkhow={providerFindInvestor.handLePersmission}
      />
    </>
  );
};
