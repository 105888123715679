import CompleteInfo from '../screen/container/acount/complete-create-account-isv/complete-info/complete-info';
import CompleteAdresse from '../screen/container/acount/complete-create-account-isv/complete-adresse/complete-adresse';
import CompleteInterested from '../screen/container/acount/complete-create-account-isv/complete-interested/complete-interested';
import CompleteProfilicture from '../screen/container/acount/complete-create-account-isv/complete-profil-picture/complete-profil-picture';
import CompleteAccount from '../screen/container/acount/complete-create-account-isv/complete-account/complete-account';
import ActivateEmail from '../screen/container/acount/complete-create-account-isv/activate-email/activate-email';
import MatchIsv from '../screen/container/find-isv-matching/matching-result';
import UnlockedIsvOEM from '../screen/container/find-isv-matching/unlocked-isv';
import FindMoreIsv from '../screen/container/find-isv-matching/find-more';
import Favorite from '../screen/container/find-isv-matching/favorite-page';

export const routesIsv = [
  {
    path: '/complete-info',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteInfo,
  },
  {
    path: '/complete-adresse',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteAdresse,
  },
  {
    path: '/complete-interested',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteInterested,
  },
  {
    path: '/complete-profil-picture',
    guard: 'not-logged-page',

    exact: true,
    component: CompleteProfilicture,
  },
  {
    path: '/complete-account',
    guard: 'public',
    exact: true,
    component: CompleteAccount,
  },
  {
    path: '/activate_account',
    guard: 'not-logged-page',
    exact: true,
    component: ActivateEmail,
  },

  //PRIVATE ROUTE
  {
    path: '/isv/match',
    guard: 'private',
    exact: true,
    component: MatchIsv,
  },

  {
    path: '/isv/favorite-page',
    guard: 'private',
    exact: true,
    component: Favorite,
  },
  {
    path: '/isv/find-more',
    guard: 'private',
    exact: true,
    component: FindMoreIsv,
  },
  {
    path: '/isv/isv-unlocked',
    guard: 'private',
    exact: true,
    component: UnlockedIsvOEM,
  },
];
